import React from "react";
import Modal from "react-bootstrap/Modal";
import { FcOk } from "react-icons/fc";
import { FiLink2 } from "react-icons/fi";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const CongratulationModal = ({
  showCongratulationModal,
  setShowCongratulationModal,
  data
}) => {
  const handleClose = () => setShowCongratulationModal(false);
  return (
    <Modal
      show={showCongratulationModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <div className="px-2 pb-2 border rounded border-primary">
        <Modal.Header closeButton className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" p-3 rounded mb-3 d-flex  flex-column align-items-center">
            <h4 className="fw-bold mb-4">Congratulations!</h4>
            <div
              style={{
                height: "150px",
              }}
            >
              <img
                src={data?.image}
                alt={data?.image}
                className="w-100 h-100"
              />
            </div>
            <p className="mt-3 fw-bold">{data?.title}</p>
            <div className="my-4 fw-bold">
              {" "}
              <FcOk className="fs-4 me-3" /> YOUR POOL IS COMPLETE & LIVE NOW!
            </div>
            <div className="d-flex gap-4 mb-4">
              <FiLink2 />
              <FaFacebookF />
              <FaTwitter />
              <FaInstagram />
            </div>
            <div
              className="w-100 fw-bold"
              onClick={() => setShowCongratulationModal(false)}
            >
              <button className="btn btn-primary  w-100 fw-bold p-3">
                VIEW POOL LISTING!
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default CongratulationModal;
