import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { TfiReload } from "react-icons/tfi";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { GoCreditCard } from "react-icons/go";
import { FaEthereum } from "react-icons/fa";
import { MdShoppingCart } from "react-icons/md";

import Web3 from "web3";
import {
  useAccount,
  useConnect,
  useNetwork,
  useSigner,
  useSwitchNetwork,
} from "wagmi";
import { useWeb3React } from "@web3-react/core";

import metamaskImg from "../assets/images/svg/metamask.svg";
import coinbase from "../assets/images/svg/coinbase.svg";
import WalletConnectProvider from "@walletconnect/web3-provider";
import wallet from "../assets/images/svg/walletconnect.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./new.css";
import {
  BuyModal,
  BuyStep,
  BidModal,
  BidStep,
  useTokens,
  useListings,
  useTokenActivity,
  useAttributes,
} from "@reservoir0x/reservoir-kit-ui";
import Loading from "../components/Loading";
import CustomOverlay from './CustomOverlay'
import { notFoundImg } from "../utils/images.util";
import { useDispatch } from "react-redux";
import { showConnectModal } from "../admin/redux/actions/connectModal";

const User = ({connectAccount}) => {

  const { pid, token } = useParams();

  const { data: tokenDatas } = useTokens({
    tokens: [`${pid}:${token}`],
  });
  const tokenData = tokenDatas?.length ? tokenDatas[0]: null;
  const amount = tokenData?.market?.floorAsk?.price?.amount?.decimal??0;
  const { data: listingDatas } = useListings({
    token: `${pid}:${token}`,
    contract: pid,
    source: 'opensea.io'
  });

  console.log(tokenDatas, "tokendatas");
  console.log(listingDatas, "listingDatas");

  const creatable = listingDatas?.length > 0 && listingDatas[0].validUntil > (new Date().getTime())/1000;

  const image_url = tokenDatas?.length ? tokenData.token.image : notFoundImg;

  const { data: activity } = useTokenActivity([`${pid}:${token}`]);
  // const {
  //   data: listings,
  //   fetchNextPage,
  //   hasNextPage,
  // } = useListings({
  //   contracts: [tokenData?.token.contract],
  // });

  // console.log("tok", tokensasdf);
  console.log("activity", activity);
  // console.log("attribute", atributesadfasfd);
  // const { account } = useWeb3React();
  const { t } = useTranslation();

  const { address, isConnected } = useAccount();
  console.log("address1", address);
  const [step, setStep] = useState(0);
  const [isloading, setIsloading] = useState(true);
  const openState = useState(false);
  const [collection, setCollection] = useState({});
  const [attribute, setAttribute] = useState([]);
  const [myPublicAddress, setMyPublicAddress] = useState(null);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const { data: signer } = useSigner();
  const { chain: activeChain } = useNetwork();
  const infuraKey = "144c623a861848f796ec67bbe424feb2";
  const [shownext, setShownext] = useState(false);
  const [listing, setListing] = useState({});
  const [offers, setOffers] = useState({});
  const [open, setOpen] = useState(false);
  const [payType, setPayType] = useState('bank')
  let [jpyRate, setJpyRate] = useState(0);

  const dispatch = useDispatch();
  const setConnectShow = (value) => {
    dispatch(showConnectModal(value));
  }

  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: 1,
  });
  const attributes = useAttributes(pid);
  console.log("attributesaaa", attributes);
  const isInTheWrongNetwork = Boolean(signer && activeChain?.id !== 1);
  const [payment, setPayment] = useState("");
  const canBuy = pid && token && !isInTheWrongNetwork;
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const handleShownext = () => {
    setTimeout(() => {
      setShownext(true);
    }, 0);
    setShow(false);
  };

  const isMetaMaskInstalled = useCallback(() => {
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }, []);

  const _handleConnectWallet = useCallback(async () => {
    setShow(false);
    setShownext(false);
    const modal = document.getElementById("modal-metamask");

    if (!isMetaMaskInstalled()) {
      modal.classList.add("show");
      modal.style.display = "block";
      return;
    }
    try {
      connectAccount()
    } catch (error) {
      console.error(error);
    }
  }, [isMetaMaskInstalled]);

  const walletConnectProvider = (chainIdParam) => {
    return {
      connect: async () => {
        console.log("WalletConnect");
        try {
          let providerData = {};
          switch (chainIdParam) {
            case 1:
              providerData = {
                infuraId: infuraKey,
                chainId: 1,
              };
              break;
            case 42:
              providerData = {
                infuraId: infuraKey,
                chainId: 42,
              };
              break;
            case 5:
              providerData = {
                infuraId: infuraKey,
                chainId: 5,
              };
              break;
            case 56:
              providerData = {
                chainId: 56,
                rpc: {
                  56: `https://bsc-dataseed.binance.org/`,
                },
                qrcodeModalOptions: {
                  mobileLinks: ["trust"],
                },
              };
              break;
            default:
              break;
          }
          const provider = new WalletConnectProvider(providerData);
          await provider.enable();
          const web3 = new Web3(provider);
          const chainId = await web3.eth.getChainId();

          if (address && chainId) {
            setMyPublicAddress(address);

            provider.on("accountsChanged", async (accounts) => {
              console.log("WC accountsChanged", accounts);
              if (accounts[0]) {
                setMyPublicAddress(accounts[0]);
              } else {
              }
            });

            provider.on("chainChanged", async (chainIdItem) => {
              console.log("WC chainChanged", chainIdItem);
            });

            provider.on("disconnect", async () => {
              console.log("WC disconnect");
            });

            console.log("WC account", address);
            console.log("WC chainId", chainId);
          }
        } catch (error) {
          console.log(error);
        }
      },
    };
  };
  

  // const _handleConnectWallet = useCallback(async () => {
  //   const modal = document.getElementById("modal-metamask");

  //   if (!isMetaMaskInstalled()) {
  //     modal.classList.add("show");
  //     modal.style.display = "block";
  //     return;
  //   }
  //   try {
  //     await window.ethereum.request({ method: "eth_requestAccounts" });
  //     const accounts = await window.ethereum.request({
  //       method: "eth_accounts",
  //     });
  //     const walletAddress =
  //       accounts[0].split("").slice(0, 6).join("") +
  //       "..." +
  //       accounts[0]
  //         .split("")
  //         .slice(accounts[0].length - 7, accounts[0].length)
  //         .join("");
  //     setMyPublicAddress(walletAddress);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [isMetaMaskInstalled]);


  const _handleWalletConnect = useCallback(async () => {
    setShow(false);
    setShownext(false);
    try {
      const provider = new WalletConnectProvider({
        infuraId: "84842078b09946638c03157f83405213", // Required
      });

      //  Enable session (triggers QR Code modal)
      await provider.enable();
      const web3 = new Web3(provider);
      let accounts = await web3.eth.getAccounts();
    
      const walletAddress =
        accounts[0].split("").slice(0, 6).join("") +
        "..." +
        accounts[0]
          .split("")
          .slice(accounts[0].length - 7, accounts[0].length)
          .join("");
      setMyPublicAddress(walletAddress);
    } catch (error) {
      console.error(error);
    }
  }, [isMetaMaskInstalled]);

  const triggerBuy = (
    <button className="buy_nows btn btn-primary">
      <span className="buy_now_span">Buy Now</span>

      <span className="cartBuy ">
        <MdShoppingCart />
      </span>
    </button>
    // <button
    //   className="fs-6 fs-lg-2 btn2"
    //   style={{
    //     marginBottom: "20px",
    //     margin: "10px",
    //     border: "none",
    //     color: "white",
    //     width: "40%",
    //     fontWeight: "500",
    //     backgroundColor: "#76a7db",
    //     boxShadow: "1px 20px 20px 2px rgba(25, 26, 25,0.4)",
    //   }}
    // >
    //   Buy Now
    // </button>
  );

  const triggerAuction = (
    // <button
    //   className="fs-6 fs-lg-2 btn2"
    //   style={{
    //     marginBottom: "20px",
    //     border: "none",
    //     color: "white",
    //     width: "40%",
    //     fontWeight: "500",

    //     backgroundColor: "#76a7db",
    //     boxShadow: "1px 20px 20px 2px rgba(25, 26, 25,0.4)",
    //   }}
    // >
    //   Bid
    // </button>
    <button className="make_offer btn btn-primary">Make Offer</button>
  );

  const handleBuyCredit = async () => {
    if(payType == "slash") {
      await buyWithCrypto();
    }
    else {
      await buyWithSkyvisit();
    }
  };

  const buyWithCrypto = async () => {
    const usdt_result = await axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      )
      .catch(e => {
        return
      })
    if(!usdt_result) {
      alert("Fetching usd price failed")
      return
    }
    console.log("usdt_rate", usdt_result);
    const {ethereum} = usdt_result.data;
    if(!ethereum) {
      alert("Fetching usd price failed");
      return
    }
    let actual_price_in_usd = Math.round(ethereum.usd * amount);
    let obj = {
      account: address,
      email: email,
      eth_amount: amount,
      currency: "USD",
      price: actual_price_in_usd,
      listingId: 0,
      payType,
      type: 'collection'
    };

    // try {
    //   const response = await axios.post(
    //     `/payment`,
    //     obj
    //   );
    //   console.log("response", response.data);
    //   if (response.status === 200) {
    //     setShownext(false);
    //     setTimeout(() => {
    //       alert("Payment process sent to your mail, Please check");
    //     }, 150);
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
    try {
      console.log(obj);
      if(obj.price > 0) {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment_slash`, obj);
        console.log("response1", response.data);
        if (response.status === 200 && response.data.success) {
          setTimeout(() => {
            alert("Payment process sent to your mail, Please check");
            setShownext(false);
          }, 150);
        }
      }
      else {
        alert(`price is ${obj.price}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setShowCongratulationModal(true);
  }

  const buyWithSkyvisit = async () => {
    let actual_price_in_jpy = Math.round(jpyRate * amount);
    console.log(actual_price_in_jpy, "actual price");
    console.log(email, 'email');
    let obj = {
      account: address,
      email: email,
      eth_amount: amount,
      currency: "JPY",
      price: actual_price_in_jpy,
      listingId: 0,
      payType,
      type: 'rockpool'
    };

    try {
      if(obj.price > 0) {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment`, obj);
        console.log("response", response.data);
        if (response.status === 200 && response.data.success) {
          setTimeout(() => {
            alert("Payment process sent to your mail, Please check");
            setShownext(false);
          }, 150);
        }
      }
      else {
        alert(`price is ${obj.price}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const goToCreatePool = async () => {
    if(pid && token) {
      const apilink = `https://testnets-api.opensea.io/v2/orders/goerli/seaport/listings?asset_contract_address=${pid}&token_ids=${token}&limit=1`;
      await fetch(apilink)
        .then(res =>  res.json())
        .then((data) => {
          if(data?.orders?.length && data?.orders[0].order_hash) {
            // navigator(`/rockpool/create/pool-details/${pid}/${token}`)
            setOpen(true)
          }
          else {
            alert("Such listing can not be found on opensea.")
          }
        })
    }
  }

  const fetchPrice = async () => {
    await axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=JPY"
      )
      .then((res) => {
        setJpyRate(jpyRate = res?.data?.ethereum.jpy)
      });
  };

  useEffect(() => {
    fetch(`https://testnets-api.opensea.io/api/v1/asset/${pid}/${token}`)
      .then((res) => res.json())
      .then((data) => {
        setCollection(data);
        setIsloading(false);
      });
    fetch(
      `https://api-goerli.reservoir.tools/orders/asks/v4?token=${pid}%3A${token}`
    )
      .then((res) => res.json())
      .then((data) => {
        setListing(data);
      });
    fetch(
      `https://api-goerli.reservoir.tools/orders/bids/v5?token=${pid}%3A${token}`
    )
      .then((res) => res.json())
      .then((data) => {
        setOffers(data);
      });
      
    fetchPrice();
  }, [pid, token]);

  // console.log("listing", listing?.orders[0]?.id);
  const attributeFunc = () => {
    const options = {
      method: "GET",
      headers: {
        accept: "*/*",
        "x-api-key": "c8c99b47-ac0e-5677-915a-1f0571480193",
      },
    };
    fetch(
      `
    https://api-goerli.reservoir.tools/collections/${pid}/attributes/explore/v4`,
      options
    )
      .then((res) => res.json())
      .then((data) => setAttribute(data.attributes));
  };
  useEffect(() => {
    attributeFunc();
  }, []);

  const newAttribute = [];
  for (let i = 0; i < attribute.length; i++) {
    let neww = attribute[i];
    if (!attribute.includes) {
      newAttribute.push(neww);
    }
  }
  const uniqueArr = attribute.filter(
    (obj, index, self) => index === self.findIndex((t) => t.key === obj.key)
  );

  // console.log("trending page", pid);
  // console.log("trending collection: single", collection[0]);
  const metamask = async () => {
    let connector;
    console.log(connectors);
    if (typeof window.ethereum) {
      connector = connectors[0];
    } else {
      connector = connectors[2];
    }
    await connect({ connector });
    // if (isLoading) {
    // }
  };
  console.log("attribute", attribute);
  console.log("collection", collection);
  // const sortedData = activity.sort((a, b) => a.type.localeCompare(b.type));
  const newtimeDuration = (timestamp) => {
    const date = new Date(timestamp);
    const days = Math.floor(date / (24 * 60 * 60 * 1000));
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    if (days >= 1) {
      return `${days} days`;
    } else if (hours >= 1) {
      return `${hours} hours`;
    } else {
      return `${minutes} minutes`;
    }
  };

  const [filterData, setFiterData] = useState([]);

  const handleFilter = (data) => {
    if (filterData.length > 0) {
      return filterData.includes(data.type);
    } else {
      return true;
    }
  };

  const handleDataFiltering = (e) => {
    if (!filterData.includes(e.target.value)) {
      setFiterData((curent) => [...curent, e.target.value]);
    } else if (filterData.includes(e.target.value)) {
      const newFilterDatas = filterData.filter((i) => i !== e.target.value);
      setFiterData(newFilterDatas);
    }
  };

  const handleRefreshClick = () => {
    // attributeFunc();

    const options = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        "x-api-key": "c8c99b47-ac0e-5677-915a-1f0571480193",
      },
      body: JSON.stringify({
        overrideCoolDown: false,
        token: `${tokenData.token.collection?.id}:${tokenData.token.tokenId}`,
      }),
    };

    fetch("https://api-goerli.reservoir.tools/tokens/refresh/v1", options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response?.statusCode == 425) {
          alert("Refress token failed Too early request sent");
        } else {
          alert("Refress token success");
        }
      })
      .catch((err) => console.log(err));
  };

  // console.log("colections", collection);

  return (
    <>
      <CustomOverlay open={open} setOpen={setOpen} contractAddress={pid} tokenId={token} />
      {isloading ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-danger text-center ">
            {/* No item available in this collection */}
            <Loading />
          </h1>
        </div>
      ) : (
        <div>
          <div className="container py-5 my-5">
            <div className="row g-5">
              <div className="col-12 col-lg-6">
                <img
                  className="w-100 border rounded mb-4"
                  src={collection?.image_url??image_url}
                  alt=""
                />
                <div className="">
                  {/* <h6>{t("attributes")}</h6> */}
                  <div className="row g-3">
                    {/* asfd */}
                    {attributes?.data?.map((attribute, index) => {
                      return (
                        <div key={index} className="col-12 col-lg-6 ">
                          <div className="bg-light rounded p-3">
                            <p
                              className="text-primary p-0 m-1"
                              style={{ fontSize: "12px" }}
                            >
                              {attribute.key}
                            </p>

                            <div className="attribute_wrap">
                              <div className="d-flex justify-content-between">
                                <p
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  {attribute?.values[0].value}
                                </p>
                                <div className="text-end">
                                  <p
                                    className="p-0 m-0"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <FaEthereum />{" "}
                                    {attribute?.values[0].floorAskPrice}{" "}
                                  </p>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "right",
                                    }}
                                  >
                                    Floor
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* asfd */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <Link
                    className="fs-5"
                    to={`/trending/${collection?.asset_contract?.address}`}
                  >
                    {" "}
                    <AiOutlineArrowLeft className="me-2" />
                    {collection?.asset_contract?.name}
                  </Link>
                  <button onClick={handleRefreshClick} className="reload">
                    <TfiReload className="reload-icon" />
                  </button>
                </div>

                <div className=" mb-3">
                  <h4> {collection?.asset_contract?.name}</h4>

                  <div className="d-flex gap-3 align-items-center">
                    <p className="text-secondary mb-0">{t("owner")}</p>
                    {collection && (
                      <img
                        width={20}
                        className="rounded-circle"
                        src={
                          collection?.top_ownerships?.length > 0 && collection?.top_ownerships[0]?.owner?.profile_img_url
                        }
                        alt=""
                      />
                    )}

                    <h6 className="mb-0">
                      {collection?.top_ownerships?.length > 0 &&
                      collection?.top_ownerships[0]?.owner?.address ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://opensea.io/${collection?.top_ownerships?.length > 0 && collection?.top_ownerships[0]?.owner?.address}`}
                        >
                          {collection?.top_ownerships[0]?.owner?.address?.slice(
                            0,
                            4
                          ) +
                            "..." +
                            collection?.top_ownerships?.length > 0 && collection?.top_ownerships[0]?.owner?.address?.slice(
                              collection?.top_ownerships[0]?.owner?.address
                                ?.length - 4,
                              collection?.top_ownerships[0]?.owner?.address
                                ?.length
                            )}
                        </a>
                      ) : (
                        <p>No Owner</p>
                      )}
                    </h6>
                  </div>
                </div>

                <div className=" mb-3">
                  <div className="btnboxfix d-flex justify-content-between">
                    <div>
                      <p>{t("listPrice")}</p>
                      <h5>
                        {
                          // tokenData?.market?.floorAsk?.price?.amount
                          //   ?.decimal
                        }
                        {/* {collection?.collection?.stats?.average_price
                          ? collection?.collection?.stats?.average_price.toFixed(
                              4
                            )
                          : "-"} */}
                      </h5>
                    </div>
                    <div>
                      <p>{t("topOffer")}</p>
                      {/* {Math.max(
              collection?.floorSale["1day"],
              collection?.floorSale["7day"],
              collection?.floorSale["30day"]
            )} */}
                      <h5>
                        {collection?.collection?.stats?.thirty_day_average_price
                          ? collection?.collection?.stats?.thirty_day_average_price.toFixed(
                              4
                            )
                          : "-"}
                      </h5>
                    </div>
                  </div>
                  {!address ? (
                    <div className="d-flex gap-2 align-items-center">
                      <button
                        css={{ flex: 1, justifyContent: "center" }}
                        aria-haspopup="dialog"
                        className="btn btn-primary fw-bold"
                        onClick={() => setConnectShow(true)}
                      >
                        Connect Now
                      </button>

                      {/* <button className="btn btn-light fw-bold d-flex gap-2 align-items-center justify-content-center">
                        <GoCreditCard className="fs-6 fw-bold" />
                        Add with Credit / Dedit
                      </button> */}
                    </div>
                  ) : (
                    <>
                      <div className="btn-user-wrap mb-3">
                      <BuyModal
                      trigger={triggerBuy}
                      tokenId={token}
                      collectionId={`${pid}`}
                      onClose={(data, stepData, currentStep) => {
                        if (currentStep === BuyStep.Complete) {
                          console.log("success");
                        }
                      }}
                    />
                    <BidModal
                      tokenId={token}
                      trigger={triggerAuction}
                      openState={openState}
                      collectionId={`${pid}`}
                      onClose={(data, stepData, currentStep) => {
                        if (currentStep === BidStep.Complete) {
                          console.log("success");
                        }
                      }}
                      onBidError={(error) => {
                        if (error) {
                          if (error.cause.code && error.cause.code === 4001) {
                            return;
                          }
                        }
                      }}
                    />
                  </div>
                  <div>
                  <button
                    className="d-flex w-100 btn btn-primary text-start"
                    type="button"
                    onClick={handleShownext}
                  >
                    <GoCreditCard
                      style={{ fontSize: "45px" }}
                      className=" me-4"
                    />
                    <div>
                      <span>Credit / Dedit / Bank Transfer</span>
                      <br />
                      <span style={{ fontSize: "12px" }}>
                        Buy with Mastercard or Visa
                      </span>
                    </div>
                  </button>
                  {
                    creatable ?
                      <button
                        className="d-flex w-100 btn btn-primary text-start mt-2"
                        type="button"
                        onClick={goToCreatePool}
                      >
                        <div>
                          <span>Create a pool</span>
                        </div>
                      </button>
                      : null
                  }
                </div>
                    </>
                  )}
                </div>
              
                <div className=" my-3">
                  <div className="d-flex gap-3 mb-4 justify-content-center justify-content-lg-start">
                    <button
                      className={
                        step === 0
                          ? "text-primary border-0 bg-transparent fw-bold"
                          : "text-black border-0 bg-transparent"
                      }
                      onClick={() => setStep(0)}
                    >
                      Info
                    </button>
                    <button
                      className={
                        step === 1
                          ? "text-primary border-0 bg-transparent fw-bold"
                          : "text-black border-0 bg-transparent"
                      }
                      onClick={() => setStep(1)}
                    >
                      Activity
                    </button>
                    <button
                      className={
                        step === 2
                          ? "text-primary border-0 bg-transparent fw-bold"
                          : "text-black border-0 bg-transparent"
                      }
                      onClick={() => setStep(2)}
                    >
                      Listings
                    </button>
                    <button
                      className={
                        step === 3
                          ? "text-primary border-0 bg-transparent fw-bold"
                          : "text-black border-0 bg-transparent"
                      }
                      onClick={() => setStep(3)}
                    >
                      Offers
                    </button>
                  </div>
                  {step === 0 && (
                    <div>
                      <div className="d-flex align-items-center mb-3">
                        <img
                          width={30}
                          className="rounded-3 me-3"
                          src={collection?.asset_contract?.image_url}
                          alt=""
                        />
                        <h6> {collection?.asset_contract?.name}</h6>
                      </div>
                      <p> {collection?.asset_contract?.description}</p>

                      <div className="textbox d-flex justify-content-between">
                        <p className="texts"> Contract Address :</p>
                        <a
                          href={`https://etherscan.io/address/${collection?.asset_contract?.address}`}
                          rel="noreferrer"
                          target="_blank"
                          className="fw-bold text-primary"
                        >
                          {" "}
                          {collection?.asset_contract?.address
                            ? collection?.asset_contract?.address?.slice(0, 4) +
                              "..." +
                              collection?.asset_contract?.address?.slice(
                                collection?.asset_contract?.address.length - 4,
                                collection?.asset_contract?.address.length
                              )
                            : "not available"}
                        </a>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p> Token ID :</p>
                        <p className="fw-bold">
                          {" "}
                          {collection?.token_id
                            ? collection?.token_id
                            : "not available"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p> Token Standard :</p>
                        <p className="fw-bold">
                          {" "}
                          {collection?.asset_contract?.schema_name
                            ? collection?.asset_contract?.schema_name
                            : "not available"}
                        </p>
                      </div>
                    </div>
                  )}
                  {step === 1 && (
                    <div>
                      <div className="dropdown mb-3">
                        <button
                          className="d-flex justify-content-between align-items-center border-1 bg-light py-2 text-start px-3 rounded-3 dropdown-toggle w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>All Events</span> <BsChevronDown />
                        </button>
                        <ul
                          className="dropdown-menu w-100 p-3"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <div className="d-flex justify-content-between form-check form-switch ps-0">
                              <label className="form-check-label" for="sale">
                                Sale
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="sale"
                                value="sale"
                                onClick={handleDataFiltering}
                              />
                            </div>
                          </li>
                          <li>
                            <div className="d-flex justify-content-between form-check form-switch ps-0">
                              <label className="form-check-label" for="transfer">
                                Transfer
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="transfer"
                                value="transfer"
                                onClick={handleDataFiltering}
                              />
                            </div>
                          </li>
                          <li>
                            <div className="d-flex justify-content-between form-check form-switch ps-0">
                              <label className="form-check-label" for="mint">
                                Mint
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="mint"
                                value="mint"
                                onClick={handleDataFiltering}
                              />
                            </div>
                          </li>
                          <li>
                            <div className="d-flex justify-content-between form-check form-switch ps-0">
                              <label className="form-check-label" for="ask">
                                Ask
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="ask"
                                value="ask"
                                onClick={handleDataFiltering}
                              />
                            </div>
                          </li>
                          <li>
                            <div className="d-flex justify-content-between form-check form-switch ps-0">
                              <label className="form-check-label" for="bid">
                                Bid
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="bid"
                                value="bid"
                                onClick={handleDataFiltering}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>

                      {activity.filter(handleFilter).map((acti, index) => (
                        <div key={index}>
                          <div className="d-flex justify-content-between">
                            <div>
                              <p>{acti.type}</p>
                              <p>{acti.price}</p>
                            </div>
                            <div>
                              <p>
                                {" "}
                                {newtimeDuration(acti.timestamp) + " "}
                                ago
                              </p>
                              <p className="text-primary">
                                {acti.fromAddress?.slice(0, 4)}...
                                {acti.fromAddress?.slice(
                                  acti.fromAddress.length - 4,
                                  acti.fromAddress.length
                                )}{" "}
                                <span className="text-black">to </span>
                                {acti.fromAddress?.slice(0, 4)}...
                                {acti.toAddress?.slice(
                                  acti.toAddress.length - 4,
                                  acti.toAddress.length
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  )}
                  {step === 2 && (
                    <div>
                      {listing?.orders?.length ? (
                        <div className="d-flex justify-content-between">
                          <div>
                            <p>
                              <FaEthereum />{" "}
                              {listing &&
                                listing?.orders[0]?.price.amount.decimal}{" "}
                              <span className="ms-2 text-secondary">
                                $
                                {listing &&
                                  listing?.orders[0]?.price.amount.usd.toFixed(
                                    2
                                  )}
                              </span>{" "}
                            </p>
                            <p>
                              <span className="text-secondary">from </span>
                              <span className="text-primary">
                                {listing &&
                                  listing?.orders[0]?.maker.slice(0, 4) +
                                    "..." +
                                    listing?.orders[0]?.maker.slice(
                                      listing?.orders[0]?.maker.length - 4,
                                      listing?.orders[0]?.maker.length
                                    )}{" "}
                              </span>
                            </p>
                            <p></p>
                          </div>
                          <div>
                            <button className="btn btn-primary btn-sm" type="">
                              Buy
                            </button>
                            <p>
                              {" "}
                              Expires in{" "}
                              {newtimeDuration(listing?.orders[0]?.validUntil) +
                                " "}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <p>No List is available here</p>
                      )}
                    </div>
                  )}
                  {step === 3 && (
                    <div>
                      {offers?.orders?.length ? (
                        offers?.orders.map((offer, index) => (
                          <div
                            key={index}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              <p>
                                <FaEthereum />{" "}
                                {offer && offer?.price.amount.decimal}{" "}
                                <span className="ms-2 text-secondary">
                                  ${offer && offer?.price.amount.usd.toFixed(2)}
                                </span>{" "}
                              </p>
                              <p>
                                <span className="text-secondary">from </span>
                                <span className="text-primary">
                                  {offer &&
                                    offer?.maker.slice(0, 4) +
                                      "..." +
                                      offer?.maker.slice(
                                        offer?.maker.length - 4,
                                        offer?.maker.length
                                      )}{" "}
                                </span>
                              </p>
                              <p></p>
                            </div>
                            <div>
                              <p>
                                {" "}
                                Expires in{" "}
                                {newtimeDuration(offer?.validUntil) + " "}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No List is available here</p>
                      )}
                    </div>
                  )}
                </div>

                <Modal
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  size="sm"
                  show={show}
                  onHide={() => setShow(false)}
                >
                  <Modal.Header closeButton className="mx-auto border-0 pb-0">
                    <Modal.Title className="mx-auto">
                      Connect Your Wallet
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p style={{ fontSize: "14px" }} className="text-center">
                      By connecting your wallet, you agree to our{" "}
                      <Link to="#">Terms of Service</Link> and our{" "}
                      <Link to="#">Privacy Policy</Link> .
                    </p>
                    <div
                      onClick={_handleConnectWallet}
                      id="connectWallet"
                      style={{ cursor: "pointer" }}
                      className="option d-flex justify-content-between align-items-center border-bottom"
                    >
                      <p style={{ fontSize: "20px" }} className="mb-0 fw-bold">
                        <img src={metamaskImg} alt="" /> MetaMask
                      </p>
                      <AiOutlineArrowRight className="icon" />
                    </div>

                    <div
                    onClick={() => setShow(true)}
                    style={{ cursor: "pointer" }}
                    className="option d-flex justify-content-between align-items-center border-bottom"
                  >
                    <p style={{ fontSize: "20px" }} className="mb-0">
                      <img src={wallet} alt="" /> {t("walletConnect")}
                    </p>
                    <AiOutlineArrowRight className="icon" />
                  </div>


                    <Link
                    onClick={() => setShow(false)}
                    to="/login"
                    style={{
                      height: "3rem",
                      margin: "0px",
                      padding: "0px",
                      cursor: "pointer",
                      border: "1px solid blue",
                      borderRadius: "1rem",
                      color: "blue",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="text-center cursor-pointer"
                  >
                    Login | Signup
                  </Link>
                  </Modal.Body>
                </Modal>

                <Modal show={shownext}>
                  <Modal.Header style={{ backgroundColor: "#f4f4f4" }}>
                    <Modal.Title>Bull Pass Payment</Modal.Title>
                    <Modal.Title
                      style={{ cursor: "pointer" }}
                      onClick={() => setShownext(false)}
                    >
                      &#215;
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form>
                      <div>
                        <div className="d-flex justify-content-between ">
                          <div className="d-flex gap-3">
                            <div
                              style={{ width: "60px" }}
                              className="rounded-4"
                            >
                              <img
                                className="rounded-4"
                                width={60}
                                src={tokenData?.token?.image??image_url}
                                alt={tokenData?.token?.image??image_url}
                              />
                            </div>
                            <div>
                              <p className="m-0 p-0 fw-bold fs-5">
                                {tokenData?.token?.name}
                              </p>
                              <p className="m-0 p-0 ">
                                {tokenData?.token?.collection?.name}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="m-0 p-0 fw-bold ">
                              <FaEthereum />{" "}
                              {tokenData?.market?.floorAsk?.price?.amount?.decimal.toFixed(
                                2
                              )}
                            </p>
                            <p
                              className="m-0 p-0"
                              style={{ fontSize: "14px", color: "#8e8e8e" }}
                            >
                              $
                              {tokenData?.market?.floorAsk?.price?.amount?.usd.toFixed(
                                2
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-3">
                          <div className="d-flex align-items-center">
                            <p className="fw-bold fs-5">Total</p>
                          </div>
                          <div>
                            <p className="m-0 p-0 fw-bold ">
                              <FaEthereum />{" "}
                              {tokenData?.market?.floorAsk?.price?.amount?.decimal.toFixed(
                                2
                              )}
                            </p>
                            <p
                              className="m-0 p-0"
                              style={{ fontSize: "14px", color: "#8e8e8e" }}
                            >
                              $
                              {tokenData?.market?.floorAsk?.price?.amount?.usd.toFixed(
                                2
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=" p-3">
                        <div className="btn-group">
                          <select
                            className="form-select pointer"
                            aria-label="Default select example"
                            onChange={(e) => setPayType(e.target.value)}
                          >
                            {/* <option value="slash">
                              Slash
                            </option> */}
                            <option value="bank">
                              Bank
                            </option>
                            <option value="payeasy">
                              Pay-easy(ATM transfer)
                            </option>
                            <option value="store">
                              Payment via convenience store
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="mb-3">
                        {/* <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email address
                        </label> */}
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email address"
                        />
                      </div>

                      <div className="mb-3">
                        <p className="text-black">
                          {/* Price: <FaEthereum />{" "}
                          {tokenData?.market?.floorAsk?.price?.amount?.decimal.toFixed(
                            2
                          )} */}
                          {/* {collection?.collection?.stats?.average_price
                            ? collection?.collection?.stats?.average_price.toFixed(
                                4
                              )
                            : "-"} */}
                        </p>
                      </div>
                      <button
                        className="d-flex w-100 btn btn-primary text-start"
                        type="button"
                        onClick={() =>
                          handleBuyCredit()
                        }
                      >
                        <GoCreditCard
                          style={{ fontSize: "45px" }}
                          className=" me-4"
                        />
                        <div>
                          <span>Credit / Dedit / Bank Transfer</span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            Add funds with Mastercard or Visa
                          </span>
                        </div>
                      </button>
                    </form>
                  </Modal.Body>
                  {/* <Modal.Footer>
                    <Button
                      variant="primary"
                      // onClick={() => setShownext(false)}
                      onClick={() =>
                        handleSubmit(
                          tokenData?.market?.floorAsk?.price?.amount?.decimal
                        )
                      }
                    >
                      Submit
                    </Button>
                  </Modal.Footer> */}
                </Modal>

                {/* <div className="border rounded p-3">
                  <h6>Attributes</h6>
                  <div className="row g-3">
                    {uniqueArr.map((att, index) => (
                      <div key={index} className="col-12 col-lg-6 ">
                        <div className="bg-light rounded p-3">
                          <p className="text-primary p-0 m-0"> {att?.key}</p>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6 className="p-0 m-0"> {att?.value}</h6>
                              <p className="p-0 m-0">
                                {att?.tokenCount} ({att?.onSaleCount / 100}%)
                              </p>
                            </div>
                            <div>
                              <h6 className="p-0 m-0">
                                {" "}
                                {att?.floorAskPrices}
                              </h6>
                              <p className="p-0 m-0">floor price</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
              </div>
            </div>

            {/* <div className="row g-5 my-5">
    <h1 className="text-center">Sample Images</h1>
    {collection[0]?.sampleImages?.length === 0 && (
      <h1 className="text-danger text-center my-5">
        No Sample Image Available
      </h1>
    )}
    {collection[0]?.sampleImages?.map((pic, index) => (
      <div className="col-3" key={index}>
        <img className="w-100" src={pic} alt="" />
      </div>
    ))}
  </div> */}

            {/* <div
              className="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabindex="-1"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                      Wallet
                    </h5>
                    <button
                      type="button"
                      className="btn-close "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      X
                    </button>
                  </div>
                  <div className="modal-body">
                    Empower your NFT Journey with buy and sell fractions of NFTs
                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-primary">Buy</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default User;
