import React, { useState } from "react";

import { AiOutlinePlus, AiOutlineMinusCircle } from "react-icons/ai";
import FirstColumn from "./columns/FirstColumn";
import SecondColumn from "./columns/SecondColumn";
import ThirdColumn from "./columns/ThirdColumn";

const Info = () => {
  const [activeTab, setActiveTab] = useState("artist");
  const [show, setShow] = useState(true);
  return (
    <section
      style={{ marginTop: "10px"}}
      className="container d-none d-lg-block"
    >
      <div
        className="d-flex gap-5 justify-content-between align-items-center fw-bold"
        style={{ cursor: "pointer" }}
      >
        <p
          onClick={() => setActiveTab("artist")}
          className={`${activeTab === "artist" && "text-primary"}`}
        >
          ARTIST INFO
        </p>
        <p
          onClick={() => setActiveTab("activity")}
          className={`${activeTab === "activity" && "text-primary"}`}
        >
          ACTIVIY
        </p>
        <p
          onClick={() => setActiveTab("owners")}
          className={`${activeTab === "owners" && "text-primary"}`}
        >
          TOP 10 OWNERS
        </p>
        <div className="text-end mb-3 fs-3">
          <AiOutlinePlus className="me-2" onClick={()=>setShow(true)} />
          <AiOutlineMinusCircle onClick={()=>setShow(false)} />
        </div>
      </div>
      <div className="row g-5">
        {show &&  activeTab === "artist" && <FirstColumn />}
        {show && activeTab === "activity" && <SecondColumn />}
        {show && activeTab === "owners" && <ThirdColumn />}
      </div>
    </section>
  );
};

export default Info;
