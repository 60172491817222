import { useState } from "react";
import InputRange from "react-input-range";
import { notFoundImg, openseaIcon, questionCircle } from "../utils/images.util";
import useSpecificCreatePool from "../hooks/rockpool/specific/useSpecificCreatePool";
import { useParams } from "react-router-dom";
import { ethers, utils } from "ethers";
import { useEffect } from "react";
import { useAccount, useNetwork } from "wagmi";
import { chainConfig } from "../hooks/ChainConfig";
import perpetualOpenCollectivePurchaseAbi from "../hooks/rockpool/open-collective-purchase/openCollectivePurchaseAbi.json";
import axios from "axios";
import { useListings } from "@reservoir0x/reservoir-kit-ui";

const RockpoolCreateDetails = () => {
  const [pvtPoolChecked, setPvtPoolChecked] = useState(false);
  const [curatorsFee, setCuratorsFee] = useState(0);
  const [fractionName, setFractionName] = useState("");
  const [fractionSymbol, setFractionSymbol] = useState("");
  const [priceMultiplier, setPriceMultiplier] = useState(0);
  const [refetch, setRefetch] = useState(0);
  let [Collection, setCollection] = useState("");
  let [extra, setExtra] = useState("");
  const [creating, setCreating] = useState(false);
	const [calling, setCalling] = useState(false);

	const { address, isConnected } = useAccount()

  const {contract: contractAddress, tokenId} = useParams()
  console.log(contractAddress);
  console.log(tokenId);
  const { chain: activeChain } = useNetwork();
  const walletChainId = activeChain?.id;
  const { products } = chainConfig(activeChain?.id)

  const { data: listingDatas } = useListings({
    token: `${contractAddress}:${tokenId}`,
    contract: contractAddress,
    source: 'opensea.io'
  });

  const handleChange = (e) => {
	const key = e.target.name
	const value = e.target.value
	console.log(key);
	switch (key) {
		case "fractionName":
			setFractionName(value)
			break;
		case "fractionSymbol":
			setFractionSymbol(value)
			break;
		case "priceMultiplier":
			setPriceMultiplier(value)
			break;
		default:
			break;
	}
  }

  const { handleCreate, isExecuting, isSuccess, data: createData, status: createStatus } = useSpecificCreatePool(contractAddress, tokenId, priceMultiplier, extra, walletChainId)

  const createPool = async () => {
    // const openseaData = await fetch(link)
    // console.log("openseaData",openseaData)
	const type = 'AUCTION'
	const types = ['bytes32', 'string', 'string', 'uint256', 'uint256'];
	const values = [utils.formatBytes32String(type), fractionName, fractionSymbol, 86400, 0];

	setExtra(extra = utils.defaultAbiCoder.encode(types, values))
    await handleCreate()
    console.log("isSucess",isSuccess)
  }

  const fetchData=async(item)=>{
    const query = `${process.env.REACT_APP_BASE_URL}/api/createRockPool`
    axios.post(query, item).then(res => {
      console.log(res)
  }).catch(err => { })
  }

  const saveData=async()=>{
    const provider = new ethers.providers.InfuraProvider(
      "goerli",
      "e5909f511a3f4297b5bfdc84f08dcb45"
      );
    if(isSuccess){
      console.log("this is the test")
      console.log(Collection)
      const contractErc20Fraction = new ethers.Contract(products.specific.contract.openCollectivePurchase, perpetualOpenCollectivePurchaseAbi, provider);
      const item = {
        amount: 0,
        fee:0,
        fractions:0,
        fractionsCount:0,
		contract: contractAddress,
		tokenId,
        priceMultiplier,
        image: Collection?.image_url,
        owner: address,
        isErc721Available:true,
        listingId:parseInt(await contractErc20Fraction.listingCount()) - 1,
        price:(Collection?.collection?.stats?.average_price).toString(),
        status:true,
        reservePrice: (Collection?.collection?.stats?.average_price).toString(),
        targetPrice: (Collection?.collection?.stats?.average_price).toString(),
        title:Collection?.name == null ? Collection?.asset_contract?.name: Collection?.name,
        progress: 0,
        userParticipation:0,
		kickoff: listingDatas?.length&&listingDatas[0].validFrom,
		duration: Number(listingDatas?.length&&listingDatas[0].validUntil) - Number(listingDatas?.length&&listingDatas[0].validFrom)
	  }

      await fetchData(item)
    }else{
      return console.log()
    }
  }

  useEffect(()=>{
	const callOpensea = async () => {
		setCalling(true)
		const link = `https://testnets-api.opensea.io/api/v1/asset/${contractAddress}/${tokenId}?format=json`;
		await fetch(link)
		.then(res =>  res.json())
		.then((data) => {
			if(data) setCollection(Collection = data)
			setCalling(false)
		})
		.catch(e => {
			setCalling(false)
		})
	}
    if(contractAddress && tokenId && !calling){
		callOpensea()
    }
  },[contractAddress, tokenId])
  
  useEffect(() => {
    const update = async () => {
      if (createData?.hash) {
        console.log("createData", createData);
        const result = await createData.wait(1)
        console.log("waitresult:", result);
        if(result?.transactionHash) {
          saveData()
          .then(() => {
            setRefetch(prev => prev+1)
            setCreating(false)
          })
          .catch(e => {
            setRefetch(prev => prev+1)
            setCreating(false)
          })
        }
        else {
          setCreating(false)

        }
      }
    }
    update()
  }, [createData])

  return (
		<section className='bg-item-detail d-table w-100'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-6 text-center'>
						<div className='sticky-bar'>
							<img
								src={Collection?.image_url ?? notFoundImg}
								className='img-fluid rounded-md shadow'
								alt={Collection?.image_url}
							/>
							<button className='btn btn-pills btn-soft-primary mt-4'>
								<img src={openseaIcon} style={{ paddingRight: 8 }} alt='' />
								This NFT is being sold on OpenSea
							</button>
						</div>
					</div>

					<div className='col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0'>
						<div className='p-4 bg-white rounded-md shadow-sm pb-5'>
							<div className='title-heading'>
								<h4 className='h3 fw-bold mb-0'>Create pool</h4>
							</div>
							<div className='mb-3'>
								<label className='form-label'>
									Fraction Name{" "}
									<img
										src={questionCircle}
										className='img-fluid rounded-md shadow'
										alt=''
										data-tip='The name of the NFT Fractions after the purchase is complete.'
									/>
								</label>
								<input
									name='fractionName'
									id='fractionName'
									type='text'
									className='form-control'
									value={fractionName}
									onChange={handleChange}
								/>
							</div>
							<div className='row mb-3'>
								<div className='col-md-6'>
									<label className='form-label'>
										Fraction Symbol{" "}
										<img
											src={questionCircle}
											className='img-fluid rounded-md shadow'
											alt=''
											data-tip='The symbol of your fractions with 3 to 5 letters.'
										/>
									</label>
									<input
										name='fractionSymbol'
										id='fractionSymbol'
										type='text'
										className='form-control'
										value={fractionSymbol}
										onChange={handleChange}
									/>
								</div>
								<div className='col-md-6'>
									<label className='form-label'>
										Price Multiplier{" "}
										<img
											src={questionCircle}
											className='img-fluid rounded-md shadow'
											alt=''
											data-tip='Once the NFT is purchased, it is fractionalized using the Auction method and relisted in our marketplace at a higher Reserve Price. You should choose the multiplier below as a parameter to be applied to the current NFT price to set the Reserve Price after the fractionalization.'
										/>
									</label>
									<input
										name='priceMultiplier'
										id='priceMultiplier'
										type='text'
										className='form-control'
										value={priceMultiplier}
										onChange={handleChange}
									/>
								</div>
							</div>
							{/* <div className='mb-3'>
								<label className='form-label'>
									Description{" "}
									<img
										src={questionCircle}
										className='img-fluid rounded-md shadow'
										alt=''
										data-tip='Do you wanna tell your friends or community something about this NFT?'
									/>
								</label>
								<textarea
									name='desc'
									id='desc'
									rows='3'
									className='form-control'
								/>
							</div> */}
							<div className='hstack'>
								<input
									className='form-check-input mt-0'
									type='checkbox'
									value={pvtPoolChecked}
									onChange={(e) => setPvtPoolChecked(e.target.checked)}
									id='pvtPool'
								/>
								<label
									className='form-check-label text-muted ms-2'
									htmlFor='pvtPool'>
									Private Pool (not listed on the Marketplace)
								</label>
							</div>
							{pvtPoolChecked && (
								<div className='mb-4 mt-3'>
									<label className='form-label'>Curator's Fees</label>
									<InputRange
										maxValue={20}
										minValue={0}
										formatLabel={(v) => `${v}%`}
										value={curatorsFee}
										onChange={(v) => setCuratorsFee(v)}
									/>
								</div>
							)}
							<button className='btn btn-primary rounded-md w-100 mt-3' onClick={createPool}>
								Create Rockpool
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RockpoolCreateDetails;
