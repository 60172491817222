import { CONNECT_MODAL_SHOW } from '../actions/type';

const initialState = {
    connect_modal: false,
};

const ConnectModal = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case CONNECT_MODAL_SHOW:
            return {
                ...state,
                connect_modal: payload,
            };
        default:
            return state;
    }
};

export default ConnectModal;
