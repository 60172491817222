import { FIRST_UPDATE } from '../actions/type';

export const firstUpdated = () => {
  return {
    type: FIRST_UPDATE,
  };
};

export const updated = () => {
  return async (dispatch) => {
    dispatch(firstUpdated());
  }
};
