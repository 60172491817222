import { notification } from 'antd'
import { useCallback, useEffect } from 'react'
// import { SpecificPoolItem } from '../../../models/rockpool/SpecificPoolsTypes'
import useAcquire from '../external-acquirer/useAcquire'

export default function useSpecificAcquire(
  chainId,
  specificPoolItem,
  poolIsCompleted,
  refetchData,
  Collection,
  listingId
) {
  const { acquire, status, dismiss, data, acquirable } = useAcquire(
    chainId,
    listingId,
    specificPoolItem.listed,
    poolIsCompleted,
    Collection
  )
  const handleSpecificAcquire = async () => {
    if (!specificPoolItem) {
      return
    }

    try {
      await acquire()
    } catch (_) {
      console.error({
        type: 'error',
        text: 'error',
        duration: 5
      })
    }
  }

  const notificationSuccessAddFounds = useCallback(() => {
    notification.success({
      message: `Buy NFT successfully`,
      description: `successfully`,
      placement: 'top',
      duration: 2
    })
  }, [])

  useEffect(() => {
    if (status === 'success') {
      refetchData()
      dismiss()
      notificationSuccessAddFounds()
    }
    if (status === 'reverted') {
      console.error({
        type: 'error',
        text: 'error',
        duration: 5
      })
    }
  }, [status, dismiss, refetchData, notificationSuccessAddFounds])

  return {
    handleSpecificAcquire,
    data,
    status,
    acquirable
  }
}
