import { usePrepareContractWrite, useSendTransaction } from 'wagmi'
import { chainConfig } from '../../ChainConfig'
import collectivePurchaseAbi from './collectivePurchaseAbi.json'
import { ethers } from 'ethers'
import axios from 'axios'

const getExtra = (extraData) => {
  if(!extraData) return null
  const {name2, symbol2, duration, step} = extraData;
  // Define your input parameters as JavaScript variables
  const type = "AUCTION";
  const _name = name2;
  const _symbol = symbol2;
  const fee = step;

  const {utils} = ethers;

  // Convert the inputs into their corresponding Solidity types (in this case - string, uint256 and bool)
  const _type = utils.formatBytes32String(type);
  console.log(_type)
  const _duration = duration;
  const _fee = fee;
  console.log("extradata:", [_type, _name, _symbol, _duration, _fee]);

  // Concatenate them together to form your final bytes data as a hex string
  const abiCoder = new utils.AbiCoder();
  const extra = abiCoder.encode(
    ['bytes32', 'string', 'string', 'uint256', 'uint256'],
    [_type, _name, _symbol, Number(_duration), Number(_fee)]
  );

  console.log("extra:", extra);
  return extra;
}

const useCreate = (contractAddress, tokenId, paymentToken, reservePrice, limitPrice, extension, priceMultiplier, extra, chainId, approved) => {
  console.log("usecreate", approved);
  const { products, nativeToken } = chainConfig(chainId)
  console.log("extra:", extra);
  const { config } = usePrepareContractWrite({
    address: products.specific.contract.collectivePurchase,
    abi: collectivePurchaseAbi,
    functionName: 'list',
    chainId,
    args: [contractAddress, tokenId, paymentToken, reservePrice, limitPrice, extension, priceMultiplier, getExtra(extra)]
  })
  console.log("config-asd:", config);

  const { sendTransaction, isSuccess, data, isLoading, status } = useSendTransaction(config)

  const create = async () => {
    console.log({ sendTransaction, isSuccess, data, isLoading });
    if(!approved) return
    if (sendTransaction) {
      await sendTransaction()
    }
  }

  return {
    create,
    isCreating: isLoading,
    isCreated: isSuccess,
    data,
    status,
    creatable: Boolean(create)
  }
}

export default useCreate
