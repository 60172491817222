import React from "react";
import Modal from "react-bootstrap/Modal";

const ApprovePoolModal = ({
  showApproveModal,
  setShowApproveModal,
  setShowFirstModal,
  setShowCongratulationModal,
  handleJoin,
  saveJoin,
  data,
  jpyRate,
  amount,
}) => {
  const handleClose = () => {
    setShowFirstModal(true);
    setShowApproveModal(false);
  };

  const handleApproveButton = async () => {
    console.log(handleJoin);
    await handleJoin();
    // await saveJoin();
    setShowApproveModal(false);
    // setShowCongratulationModal(true);
  };
  return (
    <>
      <Modal
        show={showApproveModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 pb-5 border rounded border-primary">
          <Modal.Header closeButton className="border-0">
            <Modal.Title>
              <p className="fw-bold m-0 p-0 text-primary">APPROVE POOL</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="border-bottom">
            <div className=" p-3 rounded mb-3 d-flex  align-items-center">
              <div className="d-flex align-items-center gap-2 w-75">
                <div className="w-50">
                  <img
                    className="w-100"
                    src={data?.image}
                    alt=""
                  />
                </div>
                <div className="w-50">
                  <h5 className="fw-bold m-0 p-0">{data?.title}</h5>
                  {/* <p className="m-0 p-0">Kidzuki Goerli</p> */}
                  {/* <p className="m-0 p-0">Rarity #124</p> */}
                </div>
              </div>

              <div className="w-25 text-end">
                <h5 className="fw-bold m-0 p-0">{data?.price.toFixed(4)} ETH</h5>
                <p className="m-0 p-0" style={{ fontSize: "12px" }}>
                  {(Number(data?.duration??0)/86400).toFixed(2)} days duration
                </p>
                <p className="m-0 p-0" style={{ fontSize: "12px" }}>
                  &#0165;{(data?.price * jpyRate).toFixed(2)}
                </p>
                <p className="m-0 p-0" style={{ fontSize: "12px" }}>
                  Total {(amount / data?.price * 100).toFixed()} Shares
                </p>
              </div>
            </div>
          </Modal.Body>
          <div className="text-start mt-3 px-5 ">
            <h6 className="fw-bold ">Go to your wallet</h6>
            <p style={{ fontSize: "14px" }}>
              You'll be asked to approve this pool from your wallet.
            </p>
            <button
              className="btn btn-primary w-100 fw-bold my-3 p-3"
              onClick={handleApproveButton}
            >
              APPROVE
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApprovePoolModal;
