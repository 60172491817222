import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow, Ally } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import iImage from '../assets/images/blog/01.jpg'
import { Link } from "react-router-dom";

const CoverflowCarousel = ({collections}) => {
  console.log('carousel collections',collections)

  collections = collections.filter(item=>item.image !=null);
  console.log('colCarousel',collections)

  return (
    <>
      {/* <!-- Coverflow Slider --> */}
      <div className="relative px-6 pb-16 sm:px-0">
        {/* <!-- Slider --> */}
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            100: {
              // width: 640,
              slidesPerView: 1,
            },
            575: {
              // width: 640,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            992: {
              // width: 768,
              slidesPerView: 5,
            },
          }}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={5}
          loop={true}
          coverflowEffect={{
            rotate: 30,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={false}
          modules={[EffectCoverflow, Pagination, Navigation]}
          navigation={{
            nextEl: ".swiper-button-next-4",
            prevEl: ".swiper-button-prev-4",
          }}
          className="swiper coverflow-slider"
        >
          {collections.map((data) => {
            return (
              <SwiperSlide key={data?.id}>
                  <Link
                    to={`/trending/${data?.id}`}
                    // onClick={() => setUserId(data?.id)}
                  >
                    <div className="card bg-white collections collection-primary rounded-md shadow p-2 pb-0" style={{width:320}}>
                      
                    <img
                    style={{ maxHeight: "245px", width:'100%', objectFit: "cover" }}
                    src={data?.image}
                    className="img-fluid shadow-sm rounded-md"
                    alt=""
                  />
                      <div className="content text-center p-4 mt-n5">
                        <div className="position-relative d-inline-flex">
                          <img
                            src={data?.image}
                            className="avatar avatar-small popular-small-img  rounded-pill img-thumbnail shadow-md"
                            alt=""
                          />
                          <span className="verified text-primary">
                            <i className="mdi mdi-check-decagram"></i>
                          </span>
                        </div>

                        <div className="mt-2">
                          <a href="" className="text-dark topName title h5">
                            {data?.name}
                          </a>
                      
                        </div>
                      </div>
                    </div>
                  </Link>
              </SwiperSlide>
              
            )
          })}

        </Swiper>

        <div className="swiper-button-prev-4 group absolute top-1/2 left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
          </svg>
        </div>
        <div className="swiper-button-next-4 group absolute top-1/2 right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
          </svg>
        </div>

        {/* <!-- end coverflow slider --> */}
      </div>
    </>
  );
};

export default CoverflowCarousel;
