
import React from "react";
import { DashedProgress } from "react-dashed-progress";
import "./CircleProgress.css";

const CircleProgress = ({progress}) => {
  const value = progress > 0 ? progress : 0;
  return (
    <div className="circleProgress">
      <DashedProgress
        value={Math.trunc(value)??0}
        barWidth={10}
        countBars={102}
        size={113}
        trailThickness={2}
        strokeThickness={3}
        strokeLinecap={"square"}
        trailColor={"#d1d3d4"}
        strokeColor={"#0d6efd"}
      />
    </div>
  );
};

export default CircleProgress;