import axios from 'axios'
import { logout, useAuthDispatch, useAuthState } from "../context/authContext";

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export function useAxios() {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();

  axios.interceptors.request.use(request => {
    if (token) {
      request.headers.common['Authorization'] = `Bearer ${token}`
    }
    request.timeout = 20000;
    return request
  })

  axios.interceptors.response.use(response => {
    // response.circularRef = {}
    // console.log(response);
    return Promise.resolve(response); 
  }, (error) => {
    // console.log("error here", error)
    if(error.hasOwnProperty("response") && error.response) {
      const { status } = error.response
      if (status === 401) {
        logout(dispatch);
      }
    }
    return Promise.reject(error)
  })
}
