import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import createPool from "../assets/images/8.png";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import mark from '../assets/images/icons/mark.png'
import girl from '../assets/images/icons/girl.png';
import { useWeb3React } from '@web3-react/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import icon1 from "../assets/images/icons/3.png"
import icon2 from "../assets/images/icons/1.png"
import OutlinedInput from '@mui/material/OutlinedInput';
import icon3 from "../assets/images/icons/6.png"
import Input from '@mui/material/Input';
import CloseIcon from '@mui/icons-material/Close';
import IosShareIcon from '@mui/icons-material/IosShare';
import "../components/styles/NFTStyles.css";
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@mui/material/TextField';
import Textarea from '@mui/joy/Textarea';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 5,
    label: '5%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 20,
    label: '20%',
  }
];


function valuetext(value) {
  return `${value}%`;
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const Title = styled.span`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
      font-weight: bold;
  `;



export default function ModalSuccess({
    openOne,
    setOpenOne,
    inApprove,
selectedItem,
onApprove,
feeFlag,
inFractionalize,
tokens,
price2,
type2,
usdAmount,
supply2,
startDate,
endDate,
openTwo,
handleCloseTwo,
handleCloseOne,
handleOpenTwo,
onFractionalize
}) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : 600,
    height: isMobile ? '100%' : '80%',
  overflow: 'scroll',
    borderRadius: '15px',
    bgcolor: 'white',
    //   border: '2px solid #000',
    boxShadow: 24,
    p:  isMobile ? 2: 3,
  };
  const styleS = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : 530,
    height: isMobile ? '100%' : '70%',
  borderRadius: '15px',
    bgcolor: 'white',
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };
  const styleOne = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : 530,
    height: isMobile ? '100%' : '70%',
  borderRadius: '15px',
    bgcolor: 'white',
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };
  const [daysLeft, setDaysLeft]=useState(0)

  const calculateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    const difference = end - start;

    // Convert the difference to days
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    setDaysLeft(days)
  };

useEffect(()=>{
    type2 === 'auction' &&  calculateDifference(startDate,endDate)
},[type2])
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
//   const [openOne, setOpenOne] = React.useState(openModal);

//   const handleOpenOne = () => {
//     handleClose();
//     setOpenOne(true);
//   };
//   const handleCloseOne = () => setOpenOne(false);


//   const [openTwo, setOpenTwo] = React.useState(false);

//   const handleOpenTwo = () => {
//     handleCloseOne();
//     setOpenTwo(true);

//   };
//   const handleCloseTwo = () => setOpenTwo(false);


  return (
    <div>
      

      <Modal
        open={openOne}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <Box sx={styleOne} className="flex">


          <div className="row  padding-ov">
<div className=' cross mb-3 text-right'>  <CloseIcon className='close' onClick={handleCloseOne} ></CloseIcon></div>
  <div className=' mb-3'> 
    <p className='font-ui-cl padding-top' >APPROVE POOL </p>
    
  </div>

  <div className=" mb-5 d-flex between ">
                <div className='d-flex between'>
                    <a>
                      <img src={selectedItem?.mainData} alt="nft image" style={{ height: '70px', borderRadius: '10px' , margin:'10px' }} />
                    </a>

                  <div className="m-t">
                    <p className="md bold-lg">{selectedItem.name}#{selectedItem.tokenId.slice(0,5)}</p>
                    <p className="sm bold-md">{selectedItem.name} Georil</p>
                    <p className="sm">rarity #{tokens[0]?.token.rarity} </p>
                  </div>
              </div>

              <div className="">
              <p className="md ">{price2} ETH</p>

              {type2 === 'auction' && <p className="sm">{daysLeft} days duration</p>}
                <p className="sm">${usdAmount} USD</p>
                <p className='sm'> Total {supply2} Shares</p>
              </div>
            </div>
            <hr />
  <div className='mb-5'>
    <p className='bold-md'>Go to your wallet
</p>
<p className='sm'>You'll be asked to approve this pool from your wallet.</p>
  </div>


  <div className="mb-3">
  {inApprove ? (
    <button className="btn btn-ui w-100 mt-3" >
    Approving...
   </button>
  )
                      : (selectedItem.isApproved ?
                        <button className="btn btn-ui w-100 mt-3" disabled>Approved </button>
                        : <button className="btn btn-ui w-100 mt-3" onClick={() => onApprove(selectedItem.itemCollection, selectedItem.tokenId)}>Approve </button>)}
 
    <button onClick={handleCloseOne} className="btn btn-ui-muted w-100 mt-3" >
     Cancel Pool
    </button>


  </div>
  <div className="mt-2">
  <h6 className="text-start">Mint Vault & Fractionalize</h6>
  <div className={`hstack flex-sm-column bg-light p-3 rounded ${feeFlag ? '' : 'd-none'}`}>
    <p className="text-dark my-auto text-start">
      Minting, in regards to NFTs,
      is the process of taking a digital asset and converting
      the digital file into a digital asset stored on the blockchain,
      making it officially a commodity that can be bought and sold.
    </p>
    {feeFlag ?
      (inFractionalize ? <button className="btn btn-ui w-100 mt-3" disabled>Paying...</button>
        : <button className="btn btn-ui w-100 mt-3" onClick={() => onFractionalize()}>Pay fee</button>)
      : <button className="btn btn-ui w-100 mt-3" disabled>Pay fee</button>}
  </div>
</div>

</div>
</Box>
      </Modal>
      <Modal
        open={openTwo}
        onClose={handleCloseTwo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleS} className="flex">
        <div className="row  padding-ov">
<div className=' cross mb-3 text-right'>  <CloseIcon className='close' onClick={handleCloseTwo} ></CloseIcon></div>

          <p className='text-center md-lg bold-md mb-3 mbm-5'>Congratulations!</p>
          <div className='text-center mb-3'>
            <img src={selectedItem?.mainData} alt={selectedItem.name} className="girl bd-radius mb-3" />

          </div>
          <div className="ow mb-3 mbm-5">
            <p className='md  text-center bold-lg mb-3 '> <span><img src={mark} width={30} alt="" /></span> Your pool is complete & live now</p>

          </div>
          <div className="mb-5 text-center">
            <span className='md border-ui text-center bold-lg mb-5 '> Pool ID</span>
          </div>
          <div className='d-flex text-center w-100 ui-icon  mb-3 mbm-5'>
          <i className=" uil-facebook-f"></i>
                <i className=" uil-instagram"></i>
                <i className=" uil-twitter"></i>
                <i className=" uil-copy-link"></i>
          </div>

          <div>
            <Link to={`/marketplace/details/${selectedItem.itemCollection}`} className="btn btn-ui rounded-md w-100" >
              View  POOL
              listing
            </Link>
          </div>
        </div>
        </Box>
      </Modal>
    </div>
  );
}
