import React from "react";
import money from "../../../../assets/moneyNote.jpeg";
import { HiCurrencyYen } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";

const SectionFourth = () => {
  return (
    <div  style={{ border: "1px solid #0d6efd" }} className=" rounded-2 row mt-3 p-2">
      <p className="buy" style={{paddingTop:5,paddingBottom:20}}>Buy Factional Shares: 10,000 JPY / Per Share</p>
      <div className="row">
        {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
          <div className=" col-3">
            <div
             style={{ border: "1px solid #0d6efd" }}
              className=" p-2 text-center rounded-1"
              // style={{ height: "50px" }}
            >
              <div className=" mx-auto">
                <img className="img-fluid w-50" src={money} alt="" />
              </div>
              <div className="first-section-text d-flex align-items-center justify-content-center" style={{fontSize:12}}> <HiCurrencyYen/> <span className="ms-1">10,000</span></div>
            </div>
            <div className="d-flex align-items-center justify-content-center" style={{fontSize:12}} >
              <span className="me-1">0.25 </span><GiHamburgerMenu />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFourth;
