import { usePrepareContractWrite, useSendTransaction } from 'wagmi'
// @ts-ignore
import { chainConfig } from '../../ChainConfig'
import collectivePurchaseAbi from './collectivePurchaseAbi.json'

const useLeave = (chainId, specificPoolItem) => {
  const { products } = chainConfig(chainId)
  const { config } = usePrepareContractWrite({
    //@ts-ignore
    address: products.specific.contract.collectivePurchase,
    abi: collectivePurchaseAbi,
    functionName: 'leave',
    args: [specificPoolItem.listingId]
  })
  const { sendTransaction, isSuccess, data, isLoading, isError, status } = useSendTransaction(config)
console.log("isError:", status);
console.log("isError:", sendTransaction);
  const leave = async () => {
    if (sendTransaction) {
      await sendTransaction()
    }
  }

  return {
    leave: sendTransaction,
    // isLoading: isLoading,
    data,
    status,
  }
}

export default useLeave
