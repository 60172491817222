import axios from "axios";

export const fetchJPYPrice = async (token) => {
    return await axios
        .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${token}&vs_currencies=JPY`
        )
        .then((res) => res?.data?.ethereum.jpy)
        .catch((e) => 0);
};
