import { useState } from "react";
import { Link,useParams } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";




import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

const ResetPassword = (props) => {
    const { resetToken } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
 

const [loginData, setLoginData] = useState({ resetToken, newPassword: "" });


  const handleSubmit = async() => {
    try {
        const res = await axios.post('/api/reset-password', loginData);
        toast.success(res?.data?.message);
        navigate("/login");
      } catch (error) {
        toast.error("Network Eror");
        console.log(error)
      }
  };

  return (
    <>
      <div className="back-to-home">
        <a
          href=""
          onClick={(e) => e.preventDefault()}
          className="back-button btn btn-pills btn-sm btn-icon btn-primary"
        >
          <FiArrowLeft className="icons" />
        </a>
      </div>

      <section className="position-relative">
        <div className="bg-video-wrapper">
          {/* <iframe src="https://player.vimeo.com/video/502163294?background=1&autoplay=1&loop=1&byline=0&title=0"></iframe> */}
          {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/TDkS7AYrqDk?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          <ReactPlayer
            url="https://www.youtube.com/watch?v=TDkS7AYrqDk"
            playing={true}
            loop={true}
            controls={false}
            width="640"
            height="360"
          />
        </div>

        <div className="bg-overlay bg-linear-gradient-2"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <h5 className="mb-4">Reset Password</h5>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="resetToken"
                              placeholder="Reset token"
                              required
                              value={resetToken}
                            />
                            <label htmlFor="resetToken">Reset Token:</label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control"
                              id="LoginPassword"
                              placeholder="Password"
                              required
                              onChange={(e) =>
                                setLoginData({
                                  ...loginData,
                                  newPassword: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="LoginPassword">{t("password")}:</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary rounded-md w-100"
                            type="submit"
                          >
                            Send
                          </button>
                     </div>


                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
