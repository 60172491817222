import axios from "axios";

export const ipfsBaseURL = "https://gateway.pinata.cloud/ipfs/";
// export const ipfsBaseURL = "https://fraart.mypinata.cloud/ipfs/";

export const getIpfshash = async (data) => {
  let config = {
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIzMTEwOTg4OS05N2Y0LTRjMTgtYmVmMS0yMzc3YTdjNTQzZjkiLCJlbWFpbCI6InNtYXNoZXI4ODE4QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiJiZDI3Mzc0NGU0ZGMzY2YwOTJkNyIsInNjb3BlZEtleVNlY3JldCI6IjMzOTFkN2I4YzgzZGQ2OTU4ZTg4ZDNlOWIzODg5ODQ2YTkwNjc2NzMxM2NmNGZlOWYzNGJkYzg1OTliNDJlMDgiLCJpYXQiOjE2ODUxNjczMzZ9.HN106UEfPe18-YBCmPJo3mg02c45F8r2yIJ0hmfFP4w'
    },
    data : data
  };
  const res = await axios(config);
  return res.data.IpfsHash;
}

// dh pinata ipfs
// headers.append("pinata_api_key", "a9b157b27afdde69d05c");
// headers.append("pinata_secret_api_key", "b6921eda5ebff947b92d90d519155857dc6c2bf4ebeaa4b4543ecddb33f78f63");
// Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJkODRjMzhmYy0wMmQ2LTRjZDEtYjQwMC01MzliZTdlZWMxNzYiLCJlbWFpbCI6ImRoaGFzYW5zYWhhMTFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImE5YjE1N2IyN2FmZGRlNjlkMDVjIiwic2NvcGVkS2V5U2VjcmV0IjoiYjY5MjFlZGE1ZWJmZjk0N2I5MmQ5MGQ1MTkxNTU4NTdkYzZjMmJmNGViZWFhNGI0NTQzZWNkZGIzM2Y3OGY2MyIsImlhdCI6MTY4NTExNTgxNH0.swyCq8QOX43TU465x9_WUbM8IHTj9lG9Pg2hSl--5ko


export const getIpfsHashFromFile = async (file) => {
  return new Promise((resolve, reject) => {
    var headers = new Headers();
    headers.append("pinata_api_key", "bd273744e4dc3cf092d7");
    headers.append("pinata_secret_api_key", "3391d7b8c83dd6958e88d3e9b3889846a906767313cf4fe9f34bdc8599b42e08");
    var formdata = new FormData();
    formdata.append("file", file);
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: formdata
    };
    fetch("https://api.pinata.cloud/pinning/pinFileToIPFS", requestOptions)
      .then(r => r.json())
      .then(r => {
        resolve(r.IpfsHash);
      })
      .catch(error => reject(error))
  })
};