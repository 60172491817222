import React, { useState } from 'react'
import { Link, useParams } from "react-router-dom";
import SpecificDetailPage from '../components/SpecificDetailPageCrowdSale'
import Banner from '../components/FraNew/Banner/Banner';
import AddFundsModal from '../components/FraNew/Modals/AddFundsModal';
import {
  ethereum,
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  gif1,
  gif2,
  gif3,
  gif4,
  gif5,
  gif6,
  profileDefault,
  collectionDefault,
  openseaIcon,
  greenFlag,
  notAvailableIcon,
} from "../utils/images.util";

const CrowdSaleDetails = () => {
  const { id } = useParams();

  const CHAIN_ID = process.env.REACT_APP_NETWORK_ID

  return (
	<>
    <SpecificDetailPage chainId={Number(CHAIN_ID) || 1} specificPoolId={id} />
	</>
	);
};

export default CrowdSaleDetails;
