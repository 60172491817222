import { usePrepareContractWrite, useSendTransaction } from 'wagmi'
import { chainConfig } from '../../ChainConfig'
import collectivePurchaseAbi from './collectivePurchaseAbi.json'

const useClaim = (chainId, listingId, buyer) => {
  const { products } = chainConfig(chainId)

  const { config } = usePrepareContractWrite({
    address: products.specific.contract.collectivePurchase,
    abi: collectivePurchaseAbi,
    functionName: 'claim',
    args: [listingId, buyer]
  })
  const { sendTransaction, isSuccess, data, isLoading, status } = useSendTransaction(config)

  const setClaim = async () => {
    if (sendTransaction) {
      await sendTransaction()
    }
  }

  return {
    isLoading: isLoading,
    setClaim: sendTransaction,
    data,
    status,
  }
}

export default useClaim
