import { usePrepareContractWrite, useSendTransaction } from 'wagmi'
// @ts-ignore
import { chainConfig } from '../../ChainConfig'
import { units } from '../../../service/UtilService'
import collectivePurchaseAbi from './collectivePurchaseAbi.json'
import { utils } from 'ethers'

const useJoin = (chainId, specificPoolItem, value) => {
  const { products, nativeToken } = chainConfig(chainId)
  const amountInUnits = units(value || '0', 18)
  // const reservePriceInUnits = units(specificPoolItem?.reservePrice || '0', 18)
  
  const { config } = usePrepareContractWrite({
    // @ts-ignore
    address: products.specific.contract.collectivePurchase,
    abi: collectivePurchaseAbi,
    functionName: 'join',
    chainId,
    args: [specificPoolItem?.listingId?.toString(), utils.parseEther(value)?.toString()],
    overrides: {value: utils.parseEther(value)?.toString()},
  })
  console.log("config:", config);
  const { sendTransaction, isSuccess, data, isLoading, status } = useSendTransaction(config)

  const join = async () => {
    if (sendTransaction) {
      await sendTransaction()
    }
  }

  return {
    join,
    // isLoading: isLoading,
    data,
    // isSuccess,
    status
  }
}

export default useJoin
