import React, { useState } from "react";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
// icons
import CurrencyIcon from "../../../assets/images/icons/1.png";
import MoneyIcon from "../../../assets/images/icons/6.png";
interface MoneyBoxProps {
  active?: boolean;
}

export default function MoneyBox({ getAmount, amount, jpyRate, priceFraction,active }) {
  const [activeValue, setActive] = useState(active || false);

  const onClickHandler = () => {
    setActive(!activeValue);
    console.log("fdgfdg", Number(amount) + Number(priceFraction))
    const shareAmount = jpyRate ? 10000 / jpyRate : 0;
    console.log("shareAmount", shareAmount);
    getAmount(activeValue ? Number(amount) - Number(shareAmount) : Number(amount) + Number(shareAmount))
  };
  return (
    <MainContainerStyled>
      <BoxWrapperStyled
        className={activeValue ? "rounded-2 active" : "rounded-1"}
        onClick={onClickHandler}
      >
        <div className="image-wrapper">
          <img src={MoneyIcon} alt="Money Icon" width="50" />
        </div>
        <div>
          <div className="icon-with-text">
            <div className="image-wrapper">
              <img src={CurrencyIcon} alt="Currency Icon" width="13" />
            </div>
            <div>
              <p style={{fontSize:12,fontWeight:500, color:"#666a6d"}}>{"10,000"}</p>
            </div>
          </div>
        </div>
      </BoxWrapperStyled>
      <div>
        <div className="icon-with-text">
          <div>
            <p className="text-md-2">{(priceFraction)?.toFixed(3) ?? '0.2'} <GiHamburgerMenu/></p>
          </div>
        </div>
      </div>
    </MainContainerStyled>
  );
}

const { MainContainerStyled, BoxWrapperStyled } = {
  MainContainerStyled: styled.div`
    .icon-with-text {
      justify-content: center;
      gap: 2px;

      .image-wrapper {
        display: flex;
      }
    }

    .text-1 {
      font-size: 7px;
      font-weight: 600;
    }

    .text-2 {
      font-size: 7.5px;
    }
  `,

  BoxWrapperStyled: styled.div`
    border: 1px solid #c5c4c5;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    margin-bottom: 5px;

    &.active {
      border-color: #5271ff;
      background-color: #5271ff;

      img {
        filter: invert(1);
      }

      .icon-with-text {
        img {
          filter: brightness(200%);
        }

        p {
          color: #ffffff;
        }
      }
    }
  `,
};
