import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Col, Input, Modal, Row, Space, Typography } from "antd";
import MoneyBox from "./shared/rockpool/MoneyBox.tsx";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import axios from "axios";
import perpetualOpenCollectivePurchaseAbi from "../hooks/rockpool/open-collective-purchase/openCollectivePurchaseAbi.json";
import SectionFirst from "./FraNew/Banner/sections/SectionFirst.js";
import SectionSecond from "./FraNew/Banner/sections/SectionSecond.js";
import SectionThird from "./FraNew/Banner/sections/SectionThird.js";
import SectionFifth from "./FraNew/Banner/sections/SectionFifth.js";
import { useAccount, useBalance, useNetwork ,useConnect} from "wagmi";
import Info from './FraNew/Info/Info';

// @ts-ignore
import { chainConfig } from "../hooks/ChainConfig";
// @ts-ignore
import { useSpecificPublicItemById } from "../hooks/rockpool/specific/useSpecificPublicItemById";
import {
  collectionDefault,
  ethereum,
  greenFlag,
  notAvailableIcon,
  openseaIcon,
  profileDefault,
} from "../utils/images.util";
import useSpecificAcquire from "../hooks/rockpool/specific/useSpecificAcquire";
import useSpecificJoinPool from "../hooks/rockpool/specific/useSpecificJoinPool";
import useClaimFractions from "../hooks/rockpool/specific/useClaimFractions";
import useSpecificLeavePool from "../hooks/rockpool/specific/useSpecificLeavePool";
// icons
import CurrencyIcon from "../assets/images/icons/1.png";
import EthIcon from "../assets/images/icons/2.png";
import FractionIcon from "../assets/images/icons/3.png";
import UserIcon from "../assets/images/icons/4.png";
import TimeIcon from "../assets/images/icons/5.png";
import SharesIcon from "../assets/images/icons/7.png";
import ActiveIcon from "../assets/images/icons/8.png";

// Static images
import NftImg from "../assets/images/collection/14.jpg";
import { ethers, utils } from "ethers";
import AddFundsModal from "./FraNew/Modals/AddFundsModal.js";
import { fetchJPYPrice } from "../utils/api.js";
import { CircularProgress } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { showConnectModal } from "../admin/redux/actions/connectModal.js";


export default function SpecificPoolComponent({ chainId, specificPoolId }) {
  const account = useAccount();
  const { products } = chainConfig(chainId);
  let [reserveAmount, setReserveAmount] = useState('1500000000000000');
  const config = chainConfig(chainId);
  const { activate, active, connector } = useWeb3React();
  const { connect, connectors, error, pendingConnector } = useConnect();
  const walletAddress = account?.address || "";
  const { loading, items } = useSpecificPublicItemById(specificPoolId, chainId);
  let [listedItem, setListedItem] = useState(false)
  const [depositAmount, setDepositAmount] = useState(0);
  let [JPYPrice, setJPYPrice] = useState(0);
  let [userSize,setuserSize] = useState(0)
  let [refetchData, setRefetchData] = useState(0)
  let [jpyRate, setJpyRate] = useState(0);
  let [amount, setAmount] = useState(0);
  const [step, setStep] = useState(0);
  let [priceFraction, setPriceFraction] = useState(null);
  let [isPoolInicialized, setisPoolInicialized] = useState("0");
  let [isWalletWithoutBalance, setisWalletWithoutBalance] = useState(true);
  let [Collection, setCollection] = useState(null);
  const priceAfterFractionalization = new BigNumber(
    items?.targetPrice || 0
  ).multipliedBy(items?.priceMultiplier || 0);
  const progress = 1 / items?.targetPrice * items?.amount * 100;
  let [data, setData] = useState({
    id: 199,
    owner: "",
    fractions: 1000,
    priceMultiplier: 1000,
    targetPrice: 100,
    status: false,
    progress: 50,
    userParticipation: 15,
    isErc721Available: false,
    image:
      "https://image.binance.vision/editor-uploads-original/9c15d9647b9643dfbc5e522299d13593.png",
    price: 1000,
    fractionsCount: 800,
    reservePrice: "0.01",
    fee: 0,
    title: "NFT SAMPLE",
    amount: 900,
  });
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [joining, setJoining] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [acquiring, setAcquiring] = useState(false);
  const [refetch, setRefetch] = useState(0)
  const [showCongratulationModal, setShowCongratulationModal] = useState(false);
  const [left, setLeft] = useState(false)
  const [claimed, setClaimed] = useState(false)

  const dispatch = useDispatch();
  const setShow = (value) => {
    dispatch(showConnectModal(value));
  }

  const isLoading = joining || leaving || claiming;

  const refetchRequest = () => {
    setRefetch(prev => prev + 1)
  }

  const poolIsCompleted =
  Number((items?.targetPrice / items?.amount) * 100) >= 100;
  const isFractionalized = items?.fractions;
  const losePool =
  items && !loading && !isFractionalized && !items?.isErc721Available;
  console.log('items',items)
  const getListing = async () => {
    try {
      const provider = new ethers.providers.InfuraProvider(
        "goerli",
        "144c623a861848f796ec67bbe424feb2"
      );

      const contractErc20Fraction = new ethers.Contract(products.specific.contract.openCollectivePurchase, perpetualOpenCollectivePurchaseAbi, provider);
      console.log("listingItem", items?.listingId)

      const listing = await contractErc20Fraction.listings(specificPoolId)
      setuserSize(userSize = utils.formatEther(await contractErc20Fraction.buyers(specificPoolId, account?.address)).toString())
      setListedItem(listedItem = listing)
      console.log("user",userSize)
    } catch (error) {
      console.log(error)
     
    }
  };

  const getAmount = (amt) => {
    setAmount(amount = amt)
  }
  const canJoin = () => {
    if (!account?.address) {
      return false;
    } 
    // else if (isWalletWithoutBalance) {
    //   return false;
    // }
    
    if (!(Number(amount) > 0)) {
      return false;
    }
    return true;
  };
  const canLeave = () => {
    if (!account?.address) {
      return false;
    } 
    // else if (isWalletWithoutBalance) {
    //   return false;
    // }
    console.log("handleLeave:", handleLeave);
    
    if (!leavable) {
      return false;
    }
    return true;
  };
  const canClaim = () => {
    if (!account?.address) {
      return false;
    } 
    // else if (isWalletWithoutBalance) {
    //   return false;
    // }
    console.log("handleClaim:", handleClaim);
    
    if (!handleClaimFractions) {
      return false;
    }
    return true;
  };
  const canAcquire = () => {
    if (!account?.address) {
      return false;
    } 
    // else if (isWalletWithoutBalance) {
    //   return false;
    // }
    console.log("handleAcquire:", handleSpecificAcquire);
    
    if (!acquirable) {
      return false;
    }
    return true;
  };
  console.log(account.address);
  const {data: balance} = useBalance({
    skip: true
  });

  const updateData=async(_progress)=>{
    const query = "/api/updateRockPool"
    axios.post(query, {"listingId": specificPoolId,"progress":_progress}).then(res => {
      console.log(res.data.likeStatus);
    }).catch(err => { })
  }
  
  const { handleSpecificAcquire, data: acquireData, status: acquireStatus, acquirable } = useSpecificAcquire(
    chainId,
    listedItem,
    poolIsCompleted && !losePool,
    refetchData,
    Collection,
    items?.listingId
  )
  console.log("handleSpecificAcquire", handleSpecificAcquire)

  const { handleJoin, isExecuting: loadingJoin ,isSuccess, status: joinStatus, data: joinData} = useSpecificJoinPool(
    chainId,
    walletAddress,
    items,
    amount.toString(),
    (items?.reservePrice)?.toString()
  );
  const { handleLeave, isExecuting: loadingLeave, status: leaveStatus, data: leaveData, leavable } = useSpecificLeavePool(
    chainId,
    items,
    items?.userParticipation || "0"
  );
  const { handleClaimFractions, isExecuting: loadClaim, status: claimStatus, data: claimData } = useClaimFractions(
    chainId,
    items.listingId,
    walletAddress
  );
  
  const winningPool =
    !loading && items && items.status === true && isFractionalized;

  const maxValueDeposit = new BigNumber(items?.targetPrice).minus(items?.amount);
  
  const activePool = !!items && items?.isErc721Available;
  const { Text } = Typography;
  const handleRemoveParticipation = () => {
    Modal.confirm({
      title: "Remove funds",
      icon: <ExclamationCircleOutlined />,
      content: `You have ${userSize || ""
        } ETH of participation. Do you want to remove it?`,
      okText: "Remove participation",
      cancelText: "Cancel",
      onOk: async () => {
        setLeaving(true)
        handleLeave();
      },
      cancelButtonProps: { style: { width: "100%", padding: "5px" } },
      okButtonProps: { style: { width: "100%", padding: "5px", margin: "auto", marginInlineStart: "0px", marginTop: 8 } },
    });
  };

  const handleJoinParticipation = () => {
    if(!canJoin()) {
      alert("No condition matches");
      return;
    }
    setShowFirstModal(true);
    // Modal.confirm({
    //   title: "Add funds",
    //   icon: <ExclamationCircleOutlined />,
    //   content: `You are adding ${amount ?? "0"
    //     } ETH in participation. Do you want to add it?`,
    //   okText: "Add funds",
    //   cancelText: "Cancel",
    //   onOk: async () => handleJoin(),
    //   cancelButtonProps: { style: { width: "100%", padding: "5px" } },
    //   okButtonProps: { style: { width: "100%", padding: "5px", margin: "auto", marginInlineStart: "0px", marginTop: 8 } },
    // });
  };

  const handleClaimParticipation = () => {
    Modal.confirm({
      title: "Claim",
      icon: <ExclamationCircleOutlined />,
      content: `You are Claiming from ${items?.id || ""
        } Fraction Do you want to claim it?`,
      okText: "Claim",
      cancelText: "Cancel",
      onOk: async () => handleClaim(),
      cancelButtonProps: { style: { width: "100%", padding: "5px" } },
      okButtonProps: { style: { width: "100%", padding: "5px", margin: "auto", marginInlineStart: "0px", marginTop: 8 } },
    });
  };
  const handleOwnerClaimParticipation = () => {
    Modal.confirm({
      title: "Claim",
      icon: <ExclamationCircleOutlined />,
      content: `You are Claiming ${items?.id || ""
        } Fraction Do you want to add it?`,
      okText: "Claim",
      cancelText: "Cancel",
      onOk: async () => handleClaim(),
      cancelButtonProps: { style: { width: "100%", padding: "5px" } },
      okButtonProps: { style: { width: "100%", padding: "5px", margin: "auto", marginInlineStart: "0px", marginTop: 8 } },
    });
  };
  const metamask = async () => {
    setShow(true);
    // let connector;
    // console.log(connectors);
    // if (typeof window?.ethereum) {
    //   connector = connectors[0];
    // } else {
    //   connector = connectors[2];
    // }
    // await connect({ connector });
    // await activate(connector);
    // if (isLoading) {
    // }
  };

  const handleUseMax = () => {
    const maxValue = maxValueDeposit.gt(new BigNumber(balance))
      ? new BigNumber(balance).toString()
      : maxValueDeposit.toString();
    setDepositAmount(maxValue);
  };

  const handleClaim = () => {
    Modal.confirm({
      title: "Claim fractions",
      content: (
        <span>
          You have
          <strong>{Number(items?.amount)?.toFixed(4)} ETH</strong>
          of participation, that is equal to
          <strong>
            {Number(listedItem?.fractionsCount || "0", 4)?.toFixed(2)} {items.name}
          </strong>
          Do you want to claim it?
        </span>
      ),
      okText: "Claim",
      cancelText: "Cancel",
      onOk: async () => {
        await handleClaimFractions();
      },
    });
  };
  
  const fetchPrice = async () => {
    await fetchJPYPrice("ethereum")
      .then((res) => setJpyRate(jpyRate = res));
    console.log("actual_price_in_jpy", jpyRate);
    console.log("actual_price_in_jpy", items?.targetPrice);
    let actual_price_in_jpy = Math.round(jpyRate * items?.targetPrice);
    // console.log("actual_price_in_jpy", actual_price_in_jpy);
    setJPYPrice(JPYPrice = actual_price_in_jpy);
    return actual_price_in_jpy;
  };
  const saveJoin = async () => {
    await fetch(`/api/updateRockPool/${items?.listingId}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        {
          amount: (Number(items.amount) + Number(amount)).toString(),
          listingId: items.listingId,
          owner: listedItem?.seller,
          fractions: listedItem?.fractions,
          priceMultiplier: items?.priceMultiplier,
          targetPrice: items?.targetPrice,
          status: true,
          // userParticipation seems to be wrong when user joins again
          userParticipation: items?.userParticipation + 1,
          // progress seems to be wrong
          progress: items?.progress + (Number(items?.targetPrice)/ Number(items?.amount + amount)),
          isErc721Available: items?.isErc721Available,
          image: items?.image,
          price: items?.price,
          fractionsCount: items?.fractionsCount,
          currentPrice:items?.currentPrice,
          reservePrice:items?.reservePrice,
          fee: items?.fee,
          title: items?.title,
        }
      ),
      
      })
      .then((res) => res.json())
      .then((data) => {
        console.log('updated by listingId=>', data )
      })
      .catch((err) => console.log(err));
  
  }

  useEffect(() => {
    items && getListing()
  }, [items]);

  useEffect(()=>{
    const callOpensea = async () => {
      const link = `https://testnets-api.opensea.io/api/v1/asset/${listedItem.collection}/${listedItem.tokenId}?format=json`;
      await fetch(link)
      .then(res =>  res.json())
      .then((data) => {
          setCollection(Collection = data)
      })
    }
      if(listedItem){
      callOpensea()
      }
    },[listedItem])
    
  useEffect(() => {
    console.log("isSuccess",isSuccess)
    if(isSuccess){

      // saveJoin()
    }
    fetchPrice();
    listedItem && setPriceFraction(priceFraction = (items?.reservePrice / listedItem?.fractionsCount) * 10)
    setisPoolInicialized(isPoolInicialized = !new BigNumber(items?.amount).gt("0"))
    setisWalletWithoutBalance(
      !new BigNumber(balance).toNumber() ||
      new BigNumber(depositAmount).gt(new BigNumber(balance)))
  }, [isSuccess, userSize, listedItem]);

  
  useEffect(()=>{
    if(joinStatus === "loading") {
      setJoining(true);
    } else if(joinStatus !== "success") {
      setJoining(false);
    }
  },[joinStatus])

  useEffect(()=>{
    if(leaveStatus === "loading") {
      setLeaving(true);
    } else if(leaveStatus !== "success") {
      setLeaving(false);
    }
  },[leaveStatus])

  useEffect(()=>{
    if(claimStatus === "loading") {
      setClaiming(true);
    } else if(claimStatus !== "success") {
      setClaiming(false);
    }
  },[claimStatus])

  useEffect(()=>{
    if(acquireStatus === "loading") {
      setClaiming(true);
    } else if(acquireStatus !== "success") {
      setClaiming(false);
    }
  },[acquireStatus])

  useEffect(() => {
    console.log("joinData", joinData)
    const update = async () => {
      if(joinData?.hash){
        const result = await joinData.wait();
        if(result?.transactionHash) {
          saveJoin()
          .then(() => {
            setJoining(false)
            refetchRequest()
            setShowCongratulationModal(true)
            getListing()
          })
          .catch(e => {
            setJoining(false)
          })
        }
      }
    }
    update()
  }, [joinData]);

  useEffect(() => {
    const update = async () => {
      if (leaveData?.hash) {
        console.log("leaveData", leaveData);
        const result = await leaveData.wait()
        console.log("waitresult:", result);
        if(result?.transactionHash) {
          saveJoin()
          .then(() => {
            setLeaving(false)
            setLeft(true)
          })
          .catch(e => {
            setLeaving(false)
          })
        }
        else {
          setLeaving(false)
        }
      }
    }
    update()
  }, [leaveData])

  useEffect(()=>{
    if(claimData?.hash) {
      claimData.wait()
      .then(() => {
        setClaimed(true)
        setLeaving(false)
      })
      .catch(e => {
        setLeaving(false)
      })
    }
  },[claimData])

  return (
    <section className="bg-item-detail d-table w-100" style={{paddingTop:75}}>
      <MainContainerStyled className="container">
        <div className="row">
          <ImageContainerStyled className="col-md-6">
            <img
              src={
                items?.image
              }
              alt={items?.image}
            />
            <Info/>
          </ImageContainerStyled>
          <DetailContainerStyled className="col-md-6 mt-5 mt-md-0">
            <ContentWrapperStyled>
            <SectionFirst amount= {items?.amount?.toFixed(2)} price={JPYPrice} progress={progress} targetEth={`${items ? (items?.targetPrice)?.toFixed(2): 0}`}/>

            <SectionSecond setAmount={setAmount} amount = {amount}
            />
            <span className="ms-2" style={{fontSize:11,fontWeight:500}}>{`${Math.round(items?.amount * jpyRate) ?? "0"}`} JPY Total</span>

            <SectionThird 
            FractionIcon={FractionIcon}
            fraction={`${items?.amount?.toFixed(2)}`}
            UserIcon={UserIcon}
            participant = {items?.userParticipation ?? 0}
            TimeIcon={TimeIcon}
            time= {((items?.duration??0) / 86400)}
            />
         
              <div className="third-container mt-2 w-100 rounded-2" style={{marginLeft:'-10px'}}>
                <div className="icon-with-text">
                  <div>
                    <img src={FractionIcon} alt="Fraction Icon" width="20" />
                  </div>
                  <div>
                    <p className="text-md-2">
                      Buy Factional Shares: 10,000 JPY / Per Share
                    </p>
                  </div>
                </div>
                <div className="money-box-wrapper ">
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8): 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                  <MoneyBox getAmount={getAmount} jpyRate={jpyRate} priceFraction={items ? ((items?.targetPrice - items?.amount)/8) : 1} amount={amount} />
                </div>
              </div>
              <SectionFifth total={`${Math.round(items?.amount * jpyRate) ?? "0"}`} fraction = { `${items ? (items?.amount)?.toFixed(2) : "0"}`}/>
              
            </ContentWrapperStyled>
            <ButtonWrapperStyled>
              {account.address?<button className="btn btn-primary w-95 fw-bold fs-5 p-2" disabled={!canJoin() || isLoading} onClick={handleJoinParticipation}>
                {joining?<CircularProgress color="secondary" size={25} />:"Join Pool"}
              </button> : <button className="btn btn-primary w-95 fw-bold fs-5 p-2" onClick={metamask}>
                Connect Wallet
              </button>}
              {account && userSize >0 ?  <button className="btn btn-primary w-95 fw-bold fs-5 p-2 mt-2" onClick={handleRemoveParticipation} disabled={!canLeave() || left || isLoading}>{leaving?<CircularProgress color="secondary" size={25} />:"Leave Pool"}</button>:null}
              {account && listedItem.seller === walletAddress && !loading ? <button className="btn btn-primary w-95 fw-bold fs-5 p-2 mt-2" disabled={!canClaim() || claimed || isLoading} onClick={handleOwnerClaimParticipation}>
                {claiming?<CircularProgress color="secondary" size={25} />:"Claim Pool"}
              </button> : null}
              {account && listedItem?.amount >= Number(items?.targetPrice) && !loading ? <button className="btn btn-primary w-95 fw-bold fs-5 p-2 mt-2" disabled={!canAcquire() || isLoading} onClick={handleSpecificAcquire}>
                {acquiring?<CircularProgress color="secondary" size={25} />:"Acquire NFT"}
              </button> : null}
 
            </ButtonWrapperStyled>
          </DetailContainerStyled>
        </div>
        <AddFundsModal showFirstModal={showFirstModal} setShowFirstModal={setShowFirstModal} handleJoin={handleJoin} saveJoin={saveJoin} data={items} amount={amount} jpyRate={jpyRate} showCongratulationModal={showCongratulationModal} setShowCongratulationModal={setShowCongratulationModal}/>
      </MainContainerStyled>
    </section>
  );
}

const {
  MainContainerStyled,
  ImageContainerStyled,
  DetailContainerStyled,
  ContentWrapperStyled,
  ButtonWrapperStyled,
} = {
  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    > div {
      display: flex;
      gap: 8px;
      flex-direction: row;
      align-items: center;
    }
  `,
  Input: styled(Input)`
    input {
      height: 48px;
      border-radius: 8px;
      font-weight: 600;
      font-size: 18px;
      color: ${(props) => props.theme.black};
    }

    .ant-input-group-addon {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: none;
      background-color: ${(props) => props.theme.white};
      color: ${(props) => props.theme.black};
      padding: 0 12px;
      height: 48px;
      display: flex;
      align-items: center;

      > span {
        > img {
          height: 24px;
          width: 24px;
        }
      }
    }

    .ant-input {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;
      background-color: ${(props) => props.theme.white};
      padding-right: 12px;
      height: 48px;
      text-align: right;

      &:disabled {
        border-radius: 8px;
        border: none;
        box-shadow: none;
        outline: none;
        background-color: ${(props) => props.theme.gray["0"]};
        padding-right: 12px;
        height: 48px;
      }

      &:active,
      &:focus,
      &:hover {
        box-shadow: none;
        outline: none;
      }
    }

    .ant-input-group {
      display: flex;
    }

    .ant-input-group-addon {
      width: 145px;

      > span {
        justify-content: flex-start;
        align-items: center;
      }
    }

    .ant-input-group-addon,
    .ant-input {
      border-color: ${(props) => props.theme.gray["1"]};
    }
  `,
  MainContainerStyled: styled.div`
    padding: 20px 0 17px 20px;
    // border: 1px solid #5271ff;
    border-radius: 12px;
  `,

  ImageContainerStyled: styled.div`
    padding-right: 7px;
    text-align:right;
    img {
      max-width: 100%;
      height: 514px;
      object-fit: cover;
      border-radius:10px;
    }
  `,

  DetailContainerStyled: styled.div``,

  ContentWrapperStyled: styled.div`
    padding: 10px 14px 10px 10px;
    // border: 1px solid #5271ff;
    border-radius: 12px;

    p,
    h1 {
      margin-bottom: 0;
    }

    .text-sm {
      font-size: 8px;
      font-weight: 500;
      color: #666a6d;
    }

    .text-md {
      font-size: 9px;
      font-weight: 700;
      color: #666a6d;
    }

    .text-md-2 {
      font-size: 10px;
      font-weight: 500;
      color: #666a6d;
    }

    .text-lg {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
    }

    .text-lg-1 {
      font-size: 14.5px;
      font-weight: 600;
      color: #545454;
    }

    .text-lg-2 {
      font-size: 16.5px;
      font-weight: 600;
      color: #545454;
    }

    .icon-with-text {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .first-container {
      padding: 5px 10px;
      margin-bottom: 6px;
      border: 2px solid #5271ff;
      border-radius: 12px;

      .first-container-head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .first-container-head-left {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }

      .first-container-last {
        .first-container-last-top {
          .progress-bar {
            position: relative;
            height: 17px;
            width: 100%;
            background-color: #919296;
            border-radius: 30px;
            border: 2px solid #95999d;

            .progress {
              position: absolute;
              top: 0;
              height: 100%;
              background: rgb(72, 169, 205);
              background: linear-gradient(
                90deg,
                rgba(72, 169, 205, 1) 81%,
                rgba(255, 255, 255, 1) 100%
              );
              border-radius: 30px;
            }
          }
        }

        .first-container-last-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .second-container {
      padding: 5px 10px;
      margin-bottom: 9px;
      border: 1px solid #5271ff;

      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        text-align: center;
      }
    }

    .third-container {
      padding: 5px 10px;
      border: 1px solid #5271ff;

      .money-box-wrapper {
        display: flex;
        justify-content: center;
        gap: 8px;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-top: 11px;
        max-height: 278px;
        overflow-y: scroll;
      }
    }

    .fourth-container {
      padding: 5px 10px;
      border: 1px solid #5271ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `,

  ButtonWrapperStyled: styled.div`
    button {
      padding: 7px 0;
      border: none;
      background-color: #5271ff;
      color: #ffffff;
    }
  `,
};
