
// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyAbaeymtLwyZZDnP80CZbacUPNDdZdZOPs",
  authDomain: "smashnft-b568b.firebaseapp.com",
  projectId: "smashnft-b568b",
  storageBucket: "smashnft-b568b.appspot.com",
  messagingSenderId: "547999953630",
  appId: "1:547999953630:web:d5f6d6dbb51e7b065a084f"
};
