import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoCreditCard } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { useAccount, useBalance, useNetwork } from "wagmi";

const BuyWithCreditModal = ({
  showBuyCreditModal,
  setShowBuyCreditModal,
  setShowCongratulationModal,
  data,
  amount,
  jpyRate
}) => {
  const [email, setEmail] = useState('')
  const [payType, setPayType] = useState('bank')
  const account = useAccount();
  const handleClose = () => {
    setShowBuyCreditModal(false);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleBuyCredit = async () => {
    if(payType === "slash") {
      await buyWithCrypto();
    }
    else {
      await buyWithSkyvisit();
    }
  };

  const buyWithCrypto = async () => {
    const usdt_result = await axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      )
      .catch(e => {
        return
      })
    if(!usdt_result) {
      alert("Fetching usd price failed")
      return
    }
    console.log("usdt_rate", usdt_result);
    const {ethereum} = usdt_result.data;
    if(!ethereum) {
      alert("Fetching usd price failed")
      return
    }
    let actual_price_in_usd = Math.round(ethereum.usd * amount);
    let obj = {
      account: account.address,
      email: email,
      eth_amount: amount,
      currency: "USD",
      price: actual_price_in_usd,
      listingId: data.listingId,
      payType,
      type: 'rockpool'
    };

    // try {
    //   const response = await axios.post(
    //     `/payment`,
    //     obj
    //   );
    //   console.log("response", response.data);
    //   if (response.status === 200) {
    //     setShownext(false);
    //     setTimeout(() => {
    //       alert("Payment process sent to your mail, Please check");
    //     }, 150);
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
    try {
      console.log(obj);
      if(obj.price > 0) {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment_slash`, obj);
        console.log("response", response.data);
        if (response.status === 200 && response.data.success) {
          setTimeout(() => {
            alert("Payment process sent to your mail, Please check");
          }, 150);
        }
      }
      else {
        alert(`price is ${obj.price}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setShowCongratulationModal(true);
    setShowBuyCreditModal(false);
  }

  const buyWithSkyvisit = async () => {
    alert()
    let actual_price_in_jpy = Math.round(jpyRate * amount);
    console.log(actual_price_in_jpy, "actual price");
    console.log(email, 'email1');
    let obj = {
      account: account.address,
      email: email,
      eth_amount: amount,
      currency: "JPY",
      price: actual_price_in_jpy,
      listingId: data.listingId,
      payType,
      type: 'rockpool'
    };

    try {
      if(obj.price > 0) {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment`, obj);
        console.log("response", response.data);
        if (response.status === 200 && response.data.success) {
          setTimeout(() => {
            alert("Payment process sent to your mail, Please check");
          }, 150);
        }
      }
      else {
        alert(`price is ${obj.price}`);
      }
    } catch (error) {
      console.error("Error1:", error);
    }
    setShowBuyCreditModal(false);
  }

  return (
    <Modal
      show={showBuyCreditModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="px-2 pb-5 border rounded border-primary">
        <Modal.Header closeButton className="border-0">
        <Modal.Title className="w-100">
          <div className="d-flex justify-content-between w-100">
            <div></div>
            {/* <button variant="outline-link" className="btn" onClick={handleClose}><IoMdClose /></button> */}
          </div>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" p-3 rounded mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center gap-2 w-75">
              <div className="w-50">
                <img
                  className="w-100"
                  src={data?.image}
                  alt=""
                />
              </div>
              <div className="w-50">
                <h5 className="fw-bold m-0 p-0">{data?.title}</h5>
                {/* <p className="m-0 p-0">Rarity #124</p> */}
              </div>
            </div>

            <div className="w-25 text-end">
              <h5 className="fw-bold m-0 p-0">{data?.price.toFixed(4)} ETH</h5>
              <p className="m-0 p-0" style={{ fontSize: "12px" }}>
                &#0165;{(data?.price * jpyRate).toFixed(2)}
              </p>
            </div>
          </div>
        </Modal.Body>
        <div className=" p-3">
          <div className="btn-group">
            <select
              className="form-select pointer"
              aria-label="Default select example"
              onChange={(e) => setPayType(e.target.value)}
            >
              {/* <option value="slash">
                Slash
              </option> */}
              <option value="bank">
                Bank
              </option>
              <option value="payeasy">
                Pay-easy(ATM transfer)
              </option>
              <option value="store">
                Payment via convenience store
              </option>
            </select>
          </div>
        </div>
        <div className="p-3">
          <input
            type="text"
            className="w-100 form-control"
            placeholder="Email address"
            value={email}
            name="email"
            autoComplete="email"
            onChange={handleChangeEmail}
          />
          <p style={{ fontSize: "12px" }} className="fw-bold m-0 p-0">
            Go to your email
          </p>
          <p style={{ fontSize: "12px" }}>
            You'll be asked to confirm your email payment instructions.
          </p>
          <div className=" border border-primary rounded row mt-5 p-2 w-100 mx-auto">
            <div className="col-8 d-flex justify-content-between align-items-center">
              <div>Ownership:{(amount/data?.price*100).toFixed(2)}%</div>
              <div className="fw-bold">
                {amount} ETH <GiHamburgerMenu className="fs-5" />
              </div>
            </div>
            <div className="col-4">
              <h6 className="m-0 p-0">Total: &#0165;{Math.round(jpyRate * amount)}</h6>
            </div>
          </div>
        </div>
        {/* <button
          className="border border-primary p-3 rounded mb-3 d-flex gap-3 align-items-center bg-primary text-light hover-effect mx-3"
          onClick={handleBuyCredit}
        >
          <div className="text-light fs-1 ">
            <GoCreditCard />
          </div>
          <div className="">
            <h5 className="m-0 p-0 fw-bold">Add with Credit / Dedit</h5>
            <p style={{ fontSize: "14px" }} className="m-0 p-0">
              Add funds with Mastercard or Visa
            </p>
          </div>
        </button> */}

        <button onClick={handleBuyCredit} className="btn btn-primary d-flex gap-3 align-items-center justify-content-center w-100">
        <GoCreditCard className="fs-2" />
        <div>
        <h5 className="m-0 p-0 fw-bold">Credit / Dedit / Bank Trnsfer</h5>
            <p style={{ fontSize: "14px" }} className="m-0 p-0">
              Add funds with Mastercard or Visa
            </p>
        </div>

        </button>
      </div>
    </Modal>
  );
};

export default BuyWithCreditModal;
