import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { ethereum, polygon, arrowRight } from "../utils/images.util";
import useSpecificCreatePool from "../hooks/crowdsale/specific/useSpecificCreatePool";

import {
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  gif1,
  gif2,
  gif3,
  gif4,
  gif5,
  gif6,
  notFoundImg,
} from "../utils/images.util";
import { useConnect, useNetwork, useAccount, useContractRead } from "wagmi";
import axios from "axios";
import { chainConfig } from "../hooks/ChainConfig";
import { ethers, utils } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import NFT2 from "../components/nft2.component";
import { useWeb3React } from "@web3-react/core";
import BasicModal from "../components/FraNew/ModalsCrowdSale/CreateModal";
import { useTokens, useUserTokens } from "@reservoir0x/reservoir-kit-ui";
import useCreate from "../hooks/crowdsale/collective-crowd-purchase/useCreate";
import { fetchJPYPrice } from "../utils/api";
import { showConnectModal } from "../admin/redux/actions/connectModal";

const CrowdpadCreate = () => {
  let [Collection, setCollection] = useState("");
  let [tokenId, setTokenId] = useState("0");
  const { chain: activeChain } = useNetwork();
  let [contractAddress, setContractAddress] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  let [selectedItem, setSelectedItem] = useState(0);
  const [refetch, setRefetch] = useState(0);
  let [jpyRate, setJpyRate] = useState("0");

  const { account, chainId, library } = useWeb3React();

  const {first_updated} = useSelector(({FirstUpdate}) => {
    console.log("FirstUpdate", FirstUpdate);
    return  FirstUpdate
  })

  const dispatch = useDispatch();
  const setShow = (value) => {
    dispatch(showConnectModal(value));
  }

  const { data: itemsOrigin } = useUserTokens(account, {revalidateAll: refetch >= 0})
  const items = itemsOrigin.filter(item => item.token.kind === "erc721")
  console.log("items1:", items);

  const isMetaMaskInstalled = useCallback(() => {
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }, []);

  const checkWalletConnet = useCallback(async () => {
    if (isMetaMaskInstalled()) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (!!accounts[0]) {
        sessionStorage.setItem("connected", true);
      }
    }
  }, [isMetaMaskInstalled]);

  const fetchPrice = async () => {
    await fetchJPYPrice("ethereum")
      .then((res) => setJpyRate(jpyRate = res));
    let actual_price_in_jpy = Math.round(jpyRate * items?.price);
    return actual_price_in_jpy;
  };
  useEffect(() => {
    fetchPrice();
  }, [])

	// const { handleCreate, isExecuting, isSuccess } = useSpecificCreatePool(contractAddress, tokenId, priceMultiplier, extra, walletChainId)

	const setData=async(e)=>{
    console.log(e)
		const regex = /0x[a-fA-F0-9]{40}/g;
		const matches = regex.exec(e);
		setContractAddress(contractAddress = matches ? matches[0] : null)
		const lastSlashIndex = e.lastIndexOf("/");
		setTokenId(tokenId = e.substr(lastSlashIndex + 1))

	  await fetch(`https://testnets-api.opensea.io/api/v1/asset/${contractAddress}/${tokenId}?format=json`)
			.then(res =>  res.json())
			.then((data) => setCollection(Collection = data))
    const type = 'AUCTION'
		const types = ['bytes32', 'string', 'string', 'uint256', 'uint256'];
		const values = [utils.formatBytes32String(type), Collection?.name == null ? Collection?.asset_contract?.name: Collection?.name, Collection?.asset_contract?.symbol,"8000",parseInt(utils.parseEther((Collection?.collection?.stats?.average_price)?.toString()))?.toString()];

		// setExtra(extra = utils.defaultAbiCoder.encode(types, values))
	
  }

  const modalHandler =(collection, token, index)=>{
    setSelectedItem(selectedItem = index)
    setTokenId(tokenId=token);
    setCollection(Collection=collection)
    if(items[selectedItem]?.token) setOpen(true);
  }

  // const fetchItems = (name, page) => {
  //   let query = `/api/item/?owner=${account}&page=${page}&owned=true`;
  //   if(!account) {
  //     setLoading(false)
  //     return
  //   }
  //   setLoading(true);
  //   axios.get(query)
  //     .then(res => {
  //       setLoading(false);
  //       setItems(res.data.items);
  //     }).catch(err => {
  //       setLoading(false);
  //       setItems([]);
  //       console.log(err);
  //     });
  //   }

  useEffect(() => {
    checkWalletConnet();
  }, [checkWalletConnet]);

  const _handleConnectWallet = useCallback(async () => {
    const modal = document.getElementById("modal-metamask");

    if (!isMetaMaskInstalled()) {
      modal.classList.add("show");
      modal.style.display = "block";
      return;
    }
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (!!accounts[0]) {
        sessionStorage.setItem("connected", true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [isMetaMaskInstalled]);

  // useEffect(() => {
  //   setConnected(JSON.parse(sessionStorage.getItem("connected")));
  //   console.log("isSucess", isSuccess)

  //   if(isSuccess){
  
  //     saveData()
  //   }
  // }, [isSuccess]);

  useEffect(() => {
    
  }, [refetch]);

  return (
    <>
      <section className="bg-half-100 w-100 pb-0 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h4 className="display-6 text-dark title-dark fw-medium">
                Create a Crowdpad
              </h4>
            </div>
            {/* <div className="col-lg-4">
              <div className="hstack gap-3 justify-content-end">
                <button className="btn btn-soft-primary">
                  <img src={ethereum} style={{ paddingRight: "15px" }}  alt="image"/>
                  Ethereum
                </button>
                <button className="btn btn-soft-primary">
                  <img src={polygon} style={{ paddingRight: "15px" }}  alt="image"/>
                  Polygon
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="section pt-0">
        {!account ? 
          <section className="section pt-0">
            <div className="container text-center">
              <p style={{ fontSize: 18 }}>
                Please connect your wallet
                <br />
                <span style={{ fontSize: 14 }}>
                  To fractionalize a NFT you should connect the wallet
                </span>
              </p>
              <button
                className="btn btn-soft-light"
                onClick={() => setShow(true)}
              >
                Connect Wallet
              </button>
            </div>
          </section>
        : <>
          { items?.length < 1 ?
            <section className="section pt-0" style={{ paddingBottom: 40 }}>
              {
                loading?<div className="mt-4 text-center">
                  <button className="btn btn-pills btn-primary mx-1">
                    "Loading..."
                  </button>
                </div>:
                <div
                  className="rounded-md p-3 container text-center"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                >
                  <p style={{ fontSize: 18 }}>
                    There are no NFTs in your wallet!
                    <br />
                    <span style={{ fontSize: 15 }}>What you could do</span>
                  </p>
                  <div className="row justify-content-center text-center">
                    <div className="col-xl-4 col-md-5">
                      <ul
                        style={{
                          fontSize: 15,
                          listStylePosition: "inside",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <li>Check your network (Polygon, Ethereum, etc)</li>
                        <li>Refresh the page</li>
                        <li>Check if your wallet is connected</li>
                        <li>
                          Or <span className="text-primary">buy</span> ur first
                          NFT!
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </section>:
            <div className="container mt-4 pt-2 mt-lg-0 pt-lg-0">
              {/* <div className="d-flex justify-content-between mb-3">
                <h4>{selectedItem ? 'Selected a NFT' : 'You should select a NFT'}</h4>
                {selectedItem ? <button className="btn btn-primary rounded-md" onClick={() => clickStep(2)}> Next 🠮 </button>
                  : <button className="btn btn-muted rounded-md" style={{ cursor: 'no-drop' }}> Next 🠮 </button>}
              </div> */}
              <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4">
                {items.map(({token:data}, index) => {
                  return (
                    <div className="col" key={data.name + index}>
                      <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                        <div className="d-flex justify-content-between">
                          <div className="badge badge-link bg-warning">
                            {data?.tokenId?.length > 20 &&   'Not Supported' }
                          </div>
                          <div className="badge badge-link bg-muted">1 NFT</div>
                        </div>

                        <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
                          <Link to="#">
                            <img
                              src={data.image}
                              className="img-fluid"
                              alt=""
                            />
                          </Link>
                        </div>

                        <div className="card-body content position-relative p-0 mt-3">
                          <Link
                          to="#"
                            className="title text-dark h6"
                          >
                            {data.name} #{data.tokenId}
                            <br />
                            {data.contract}
                          </Link>
                          <br />
                          <button className="mt-3 btn btn-muted w-100"  onClick={()=>modalHandler(data.contract, data.tokenId, index)}>
                            Select NFT
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <BasicModal item={items[selectedItem]} open={open} setOpen={setOpen} setRefetch={setRefetch} jpyRate={jpyRate}/>
            </div>
          }
        </>
        }
      </section>
    </>
  );
};

export default CrowdpadCreate;
