import React from "react";
import { TbChevronDown } from "react-icons/tb";
import { Input} from "antd";
const SectionSecond = ({setAmount,amount}) => {
  return (
    <div  style={{ border: "1px solid #0d6efd" }} className=" rounded-2 row align-items-center mt-2 p-2 w-100">
      <div className="col-12 p-2 rounded-2 fw-bold" style={{ backgroundColor: "#e9f2ff" }}>
    
        <Input
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                addonAfter={"ETH"}
              />
      </div>
      
    </div>
  );
};

export default SectionSecond;
