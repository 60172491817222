import { FIRST_UPDATE } from '../actions/type';

const initialState = {
    first_updated: false,
};

const FirstUpdate = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case FIRST_UPDATE:
            return {
                ...state,
                first_updated: true,
            };
        default:
            return state;
    }
};

export default FirstUpdate;
