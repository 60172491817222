import React, { useState, useCallback, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
   client01, 
   logoLight,
    logoDark,
    collectionImg,
marketplaceImg,
fraPoolImg,
profileImg,
profileImg2,
globalImg,
joinPoolImg,
walletIcon,
settingsImg,
creatPoolImg,
searchImg,
arrowLeft
} from "../utils/images.util";
import "../../src/pages/new.css";
import PreNav from "../pages/PreNav";
import { BiCartAlt } from "react-icons/bi";
import { GoLightBulb } from "react-icons/go";
import Web3 from "web3";
import { MdOutlineDarkMode } from "react-icons/md";
import metamask from "../assets/images/svg/metamask.svg";
import coinbase from "../assets/images/svg/coinbase.svg";
import wallet from "../assets/images/svg/walletconnect.svg";
import { AiOutlineArrowRight ,AiOutlineArrowLeft} from "react-icons/ai";
import { Badge, Button, Modal } from "react-bootstrap";
import { ethers } from "ethers";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { AuthStateContext } from "../context/authContext";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Header from "../admin/components/layout/Header";
import i18next from "i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import createPool from "../assets/images/8.png";
import joinPool from "../assets/images/9.png";
import { useWeb3React } from "@web3-react/core";
import updatedLogo from "../assets/images/updated-logo.png";
import smashLogo from "../assets/images/logo/logo.png";
import { shorter } from "../utils";
import {
  CartPopover,
  useCart,
  BuyModal,
  BuyStep,
} from "@reservoir0x/reservoir-kit-ui";
import NewCart from "./NewCart";
import { PictureAsPdf } from "@mui/icons-material";
import axios from "axios";
import { firstUpdated, updated } from "../admin/redux/actions/firstUpdateActions";
import { useDispatch, useSelector } from "react-redux";
import { useConnect } from "wagmi";
import { showConnectModal } from "../admin/redux/actions/connectModal";

const Navbar = ({ nav, connectAccount ,logout, user, login}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setActive,authTkn,setUserAddress } = useContext(AuthStateContext);

  const [myPublicAddress, setMyPublicAddress] = useState("qhut0...hfteh45");
  const [headerId, setHeaderId] = useState(0);
  const [value, setValue] = useState("");
  const [collections, setCollection] = useState([]);

  // const [show, setShow] = useState(false);
  const {connect_modal: show} = useSelector(({ConnectModal}) => ConnectModal)
  const setShow = (value) => {
    dispatch(showConnectModal(value));
  }

  const [shownext, setShownext] = useState(false);
  const [openState, setOpenState] = useState(false);

  const { account, chainId, library, deactivate } = useWeb3React();
  const { disConnect } = useConnect();
  const {
    data: carts,
    clear,
    clearTransaction,
    validate,
    remove,
    add,
    checkout,
  } = useCart((aaaa) => aaaa);


  const firstUpdate = (name, page) => {
    let query = `/api/firstupdate/?owner=${account}`;
    axios
      .get(query)
      .then((res) => {
        console.log(res.data);
        dispatch(updated());
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (account) {
      firstUpdate()
      setShow(false)
    }
  }, []);

  const [logo, setLogo] = useState("");
  // const handleClosenext = () =>{)};
  const handleShownext = () => {
    setTimeout(() => {
      setShownext(true);
    }, 0);
    setShow(false);
  };
console.log('cartsNav',carts)
// `${collectionId}:${tokenId}`

let CartToken = carts.items?.map(item=>{
  return `${item?.collection.id}:${item?.token.id}`;
})

  const buyEndpointOptions = {
    method: 'POST',
    headers: {
      accept: "*/*",
      "content-type": "application/json",
      "x-api-key": "c8c99b47-ac0e-5677-915a-1f0571480193",
    },
    body: JSON.stringify({
      tokens: CartToken,
      onlyPath: false,
      currency: '0x0000000000000000000000000000000000000000',
      normalizeRoyalties: false,
      partial: false,
      skipBalanceCheck: false,
      allowInactiveOrderIds: false,
      excludeEOA: false,
      taker: account,
    })
  };
  // checkout({ ...buyEndpointOptions })

  const isMetaMaskInstalled = useCallback(() => {
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }, []);

  const checkWalletConnet = useCallback(async () => {
    if (isMetaMaskInstalled()) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
     
      if (!!accounts[0]) {
        const walletAddress =
          accounts[0].split("").slice(0, 6).join("") +
          "..." +
          accounts[0]
            .split("")
            .slice(accounts[0].length - 7, accounts[0].length)
            .join("");
        setMyPublicAddress(walletAddress);
      }
    }
  }, [isMetaMaskInstalled]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [autheticateToken, setAuthToken] = useState(null);
 
  useEffect(() => {
    let authToken = localStorage.getItem('authToken')
    setAuthToken(authToken)
    checkWalletConnet();
  }, [checkWalletConnet]);


  const _handleConnectWallet = useCallback(async () => {
    setShow(false);
    setShownext(false);
    const modal = document.getElementById("modal-metamask");

    if (!isMetaMaskInstalled()) {
      modal.classList.add("show");
      modal.style.display = "block";
      return;
    }
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
     
      const walletAddress =
        accounts[0].split("").slice(0, 6).join("") +
        "..." +
        accounts[0]
          .split("")
          .slice(accounts[0].length - 7, accounts[0].length)
          .join("");
      setMyPublicAddress(walletAddress);
    } catch (error) {
      console.error(error);
    }
  }, [isMetaMaskInstalled]);

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var immediateParent = getClosest(matchingMenuItem, "li");
        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(matchingMenuItem, ".parent-menu-item");
        if (parent) {
          parent.classList.add("active");
          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }
          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  };

  const _handleWalletConnect = useCallback(async () => {
    setShow(false);
    setShownext(false);
    try {
      const provider = new WalletConnectProvider({
        infuraId: "84842078b09946638c03157f83405213", // Required
      });

      //  Enable session (triggers QR Code modal)
      await provider.enable();
      const web3 = new Web3(provider);
      let accounts = await web3.eth.getAccounts();
    
      const walletAddress =
        accounts[0].split("").slice(0, 6).join("") +
        "..." +
        accounts[0]
          .split("")
          .slice(accounts[0].length - 7, accounts[0].length)
          .join("");
      setMyPublicAddress(walletAddress);
    } catch (error) {
      console.error(error);
    }
  }, [isMetaMaskInstalled]);
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const toggleMenu = () => {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");

    if (document.getElementById("isToggle").classList.contains("open")) {
      isOpen.style.display = "block";
    } else {
      isOpen.style.display = "none";
    }
    isOpen.addEventListener("click", function () {
      document.getElementById("isToggle").classList.remove("open");
      isOpen.style.display = "none";
    });
  };



  useEffect(() => {
    checkWalletConnet();
  }, [checkWalletConnet]);
  
  useEffect(() => {
    const options = {
      method: 'GET',
      headers: {accept: '*/*', 'x-api-key': 'c8c99b47-ac0e-5677-915a-1f0571480193'}
    };
    fetch(`https://api-goerli.reservoir.tools/collections/v5?sortBy=floorAskPrice`,options)
      .then((res) => res.json())
      .then((data) => setCollection(data.collections))
      .catch(e => {
        console.log(e);
      });
  }, []);

  //   console.log(value);

  // language change functionality start

  const [activeLang, setActiveLang] = useState("");

  const handleJapanese = () => {
    i18next.changeLanguage("ja");
    setActiveLang("ja");
  };

  const handleEnglish = () => {
    i18next.changeLanguage("en");
    setActiveLang("en");
  };

  const url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const langu = localStorage.getItem("i18nextLng");
    setActiveLang(langu);

    fetch(`${url}/api/system-config/all-picture-configuration`)
      .then((res) => res.json())
      .then((data) => setLogo(data));
  }, []);
  // language change functionality end
  // console.log("la", logo);

  const [toggle, setToggle] = useState(false);

  const [profileStep,setProfileStep] = useState(false)
  const [navStep,setNavStep] = useState(false)
  const [lanStep,setLanStep] = useState(false)

const handleToggle = ()=>{
  setToggle(!toggle)
  setNavStep(false)
  setProfileStep(false)
  setLanStep(false)
}

const [balance, setBalance] = useState(0);

const getBalance = async (address) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const balance = await provider.getBalance(address);
  const balanceInEth = ethers.utils.formatEther(balance);
 setBalance(balanceInEth.slice(0,4))
}

const proPic = "https://nftgo.io/tempImg/avatar-0.png"
const [snackBarMessage, setSnackBarMessage] = useState("");
const [openSnackbar, setOpenSnackbar] = useState(false);
 
const handleCopy = () => {
  setSnackBarMessage("Copied!");
  setOpenSnackbar(true);
};


// const { user, login,logout } = props;
const disconnect = async ()=>{
  try {
    
   const connector =  localStorage.getItem('connectorId');
   if(connector){
    localStorage.removeItem('connectorId');
    deactivate()
    disConnect()
    setProfileStep(!profileStep)
    logout();
   }else{
    localStorage.removeItem('authToken');
    localStorage.removeItem('userAddress');
   }
   
   return window.location("/");
  } catch (ex) {
    console.log(ex)
  }
}


const [searchModal, setSearchModal] = useState(false)

useEffect(() => {
  account && getBalance(account)
}, [account])
  return (
    <>
      <header id="topnav" className="defaultscroll sticky">
        <div className="container-fluid">
       
        <nav>
        <div className="logo flex-grow-1">
         <Link className="" to="/">
            
              <img
              src={logo ? smashLogo : smashLogo}
              height="40"
              alt=""
            />
          
           </Link>
        </div>
        <div className="desktopNav position-relative d-block d-lg-none" >
          <ul>
          <li className="flex-grow-1 d-none d-sm-block">
           <div className="search-bar desktop ms-2">
          <div id="itemSearch" className="menu-search mb-0">
            <form
              role="search"
              method="get"
              id="searchItemform"
              className="searchform"
            >
              <input
                type="search"
                className="search p-2 ps-5 pe-3 form-control border rounded-1 "
                name="s"
                id="searchItem"
                placeholder="  Search items, collections, and accounts"
                value={value}
                onChange={handleChange}
              />
    
              <input
                className="d-none"
                type="submit"
                id="searchItemsubmit"
                value="Search"
              />
            </form>
          </div>
          <div className="bg-white shadow rounded position-absolute w-100">
          {collections
            .filter((item) => {
              const searchTerm = value.toLocaleLowerCase();
              const collectionName = item.name.toLocaleLowerCase();
              // if()
              return (
                searchTerm && collectionName.startsWith(searchTerm)
              );
            })
            .slice(0, 5)
            .map((itm) => (
              <Link
                className="bg-white shadow"
                to={`/trending/${itm?.primaryContract}`}
              >
                <div className="p-2 d-flex align-items-center gap-2">
                  <div>
                    <img
                      width={20}
                      height={20}
                      src={itm.image}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="text-text">{itm.name}</div>
                </div>
              </Link>
            ))}
        </div>
           </div>
         </li>

         {/*POP UP SEARCH FOR MOBILE*/}
         <li className="d-sm-none">
              <div className="border rounded-1 p-2" onClick={()=>setSearchModal(true)} >
                  <img src={searchImg} height="24" />
              </div>
              <div className={`search-modal ${searchModal ? 'open' : '' }`} >
                  <div id="itemSearch" className="menu-search mb-0">
                  <form
                    role="search"
                    method="get"
                    id="searchItemform"
                    className="searchform d-flex align-items-center"
                  >
                    <img src={arrowLeft} height="24" className="position-absolute ps-0" onClick={()=>setSearchModal(false)}/>
                    <input
                      type="search"
                      className="searchmobile p-2 ps-5 pe-3 form-control border rounded-1 "
                      name="s"
                      id="searchItem"
                      placeholder="  Search items, collections, and accounts"
                      value={value}
                      onChange={handleChange}
                    />
          
                    <input
                      className="d-none"
                      type="submit"
                      id="searchItemsubmit"
                      value="Search"
                    />
                  </form>
                  </div>

                  <div className="bg-white shadow rounded position-absolute w-100">
          {collections
            .filter((item) => {
              const searchTerm = value.toLocaleLowerCase();
              const collectionName = item.name.toLocaleLowerCase();
              return (
                searchTerm && collectionName.startsWith(searchTerm)
              );
            })
            .slice(0, 5)
            .map((itm) => (
              <Link
                className="bg-white shadow"
                to={`/trending/${itm?.primaryContract}`}
                onClick={()=>setSearchModal(false)}
              >
                <div className="p-2 d-flex align-items-center gap-2">
                  <div>
                    <img
                      width={20}
                      height={20}
                      src={itm.image}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="text-text">{itm.name}</div>
                </div>
              </Link>
            ))}
        </div>
              </div>
         </li>
         {/*POP UP SEARCH FOR MOBILE*/}


          <li
          style={{ cursor: "pointer" }}
          className="list-inline-item mb-0 me-5 me-md-3 p-1 rounded-3 border"
        >
          <CartPopover
            trigger={
              <button className="cartBtn" onClick={() => setOpenState()}>
                <BiCartAlt className="fs-3" />{" "}
                <Badge className="rounded-circle" bg="primary">
                  {carts.items.length}
                </Badge>
              </button>
            }
            side={"bottom"}
            openState={openState}
            cartItems={carts.items}
          
            onConnectWallet={() => {
              !account && connectAccount();
              account && checkout({ ...buyEndpointOptions })
            }}
          />
        </li>
       
          </ul>
        </div>
        <div className="desktopNav d-none d-lg-block flex-grow-1">
        <ul>
        <li>
          <Link  to="/trending-collections">
            <div className="d-flex align-items-center gap-1">
            <img width={24} src={collectionImg} alt="" />
            <div>
            {t("collection")}
            </div>
          </div>
          </Link>
        </li>
    
        <li>
          <Link
            to="/rockpool"
          >
          <div className="d-flex align-items-center gap-1">
           <img width={24} src={fraPoolImg} alt="" />
           <div>
           SmashPool
          </div>
        </div>
        </Link>

          {/*submenu*/}
          <ul className="child_collection shadow rounded-1  px-0  d-flex flex-column">
            <li className="px-2 d-flex flex-column py-2">
              <Link
                to="/rockpool/pools"
              >
                <div className="d-flex align-items-center gap-2">
                  <img className="align-self-start" width={30} src={createPool} alt="" />
                  <div>
                    <p className="text-text">{t("joinPool")}</p>
                    <div
                      className="subtitle lh-sm"
                      style={{ fontSize: 10 }}
                    >
                      <p>Collective purchase of NFTs  </p>
                      <span  style={{ fontSize: 8 }}>- Create and join pools to buy together NFTs from Open Sea.</span>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
            <li className="px-2 d-flex flex-column py-2">
                {/* <CustomOverlay>
                  <div
                  className="subtitle lh-sm"
                  style={{ fontSize: 10 }}
                >
                  <p>Collective purchase of NFTs</p>
                  <span  style={{ fontSize: 8 }}>- Create and join pools to buy together NFTs from Open Sea.</span>
                </div>
              </CustomOverlay> */}
              <Link
                to="/rockpool/create"
              >
                <div className="d-flex align-items-center gap-2">
                  <img className="align-self-start" width={30} src={createPool} alt="" />
                  <div>
                    <p className="text-text">{t("createPool")}</p>
                    <div
                      className="subtitle lh-sm"
                      style={{ fontSize: 10 }}
                    >
                      <p>Collective purchase of NFTs  </p>
                      <span  style={{ fontSize: 8 }}>- Create and join pools to buy together NFTs from Open Sea.</span>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
            <li className="px-2 d-flex flex-column py-2">
            <Link
              to="/crowdsale"
            >
               <div className="d-flex align-items-center gap-2">
                  <img className="align-self-start" width={30} src={joinPoolImg} alt="" />
                  <div>
                    <p className="text-text">Join Crowdsale</p>
                    <div
                      className="subtitle lh-sm"
                      style={{ fontSize: 10 }}
                    >
                      <p>NFT Crowdfunding </p>
                      <span  style={{ fontSize: 8 }}>- Raise funds putting a unique NFT on sale, allowing many people 
                      to buy it together.</span>
                    </div>
                  </div>
                </div>
            </Link>{" "}
          </li>
            <li className="px-2 d-flex flex-column py-2">
              <Link
                to="/crowdsale/create"
              >
              <div className="d-flex align-items-center gap-2">
              <img className="align-self-start" width={30} src={joinPoolImg} alt="" />
              <div>
                <p className="text-text">Create Crowdsale</p>
                <div
                  className="subtitle lh-sm"
                  style={{ fontSize: 10 }}
                >
                  <p>NFT Crowdfunding</p>
                  <span  style={{ fontSize: 8 }}>- Raise funds putting a unique NFT on sale, allowing many people 
                  to buy it together.</span>
                </div>
              </div>
            </div>
              </Link>{" "}
            </li>
          </ul>
            {/*submenu*/}
      </li>
   
   {
    /*
    <li className="d-none d-xl-block">
    <Link to="/marketplace">
      <div className="d-flex align-items-center gap-1">
      <img width={24} src={marketplaceImg} alt="" />
      <div>
      {t("marketPlace")}
      </div>
    </div>
    </Link>
  </li>
    */
   }
              
    
   

      <li className="position-relative flex-grow-1">
      <div className="search-bar desktop ">
        <div id="itemSearch" className="menu-search mb-0">
          <form
            role="search"
            method="get"
            id="searchItemform"
            className="searchform"
          >
            <input
              type="search"
              className="search p-2 ps-5 pe-3 form-control border rounded-1 "
              name="s"
              id="searchItem"
              placeholder="  Search items, collections, and accounts"
              value={value}
              onChange={handleChange}
            />

            <input
              className="d-none"
              type="submit"
              id="searchItemsubmit"
              value="Search"
            />
          </form>
        </div>
      </div>
      <div className="bg-white shadow rounded position-absolute w-100">
          {collections
            .filter((item) => {
              const searchTerm = value.toLocaleLowerCase();
              const collectionName = item.name.toLocaleLowerCase();
              // if()
              return (
                searchTerm && collectionName.startsWith(searchTerm)
              );
            })
            .slice(0, 5)
            .map((itm) => (
              <Link
                className="bg-white shadow"
                to={`/trending/${itm?.primaryContract}`}
              >
                <div className="p-2 d-flex align-items-center gap-2">
                  <div>
                    <img
                      width={20}
                      height={20}
                      src={itm.image}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="text-text">{itm.name}</div>
                </div>
              </Link>
            ))}
        </div>
    </li>
       
    {/*Modal*/}
    {(account || autheticateToken||authTkn) ? (
      <Header />
    ) : (
      <li>
      <Link to="#"  onClick={() => setShow(true)}>
        <div className="d-flex align-items-center gap-2 border rounded-2 py-2 px-3">
        <img width={24} src={walletIcon} alt="" />
        <div >
        {t("connectWallet")}
        </div>
         <div className="profileNavIcon ms-3">
           <img width={24} style={{borderRadius:'50%'}} src={profileImg} alt="" />
         </div>
      </div>
      </Link>
    </li>
    )}
       <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="sm"
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title >
              {t("connectWallet")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ fontSize: "14px" }} className="text-center">
              {t("byConnectiongYourWallet")}{" "}
              <Link to="#">{t("termsOfService")}</Link> {t("andOur")}{" "}
              <Link to="#">{t("privacyPolicy")}</Link> .
            </p>
            <div
              onClick={connectAccount}
              id="connectWallet"
              style={{ cursor: "pointer" }}
              className="option d-flex justify-content-between align-items-center border-bottom"
            >
              <p style={{ fontSize: "20px" }} className="mb-0">
                <img src={metamask} alt="" /> {t("metaMask")}
              </p>
              <AiOutlineArrowRight className="icon" />
            </div>
          
            <div
              onClick={_handleWalletConnect}
              style={{ cursor: "pointer" }}
              className="option d-flex justify-content-between align-items-center border-bottom"
            >
              <p style={{ fontSize: "20px" }} className="mb-0">
                <img src={wallet} alt="" /> {t("walletConnect")}
              </p>
              <AiOutlineArrowRight className="icon" />
            </div>

            <Link
              onClick={() => setShow(false)}
              to="/login"
              style={{
                height: "3rem",
                margin: "0px",
                padding: "0px",
                cursor: "pointer",
                border: "1px solid blue",
                borderRadius: "1rem",
                color: "blue",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="text-center cursor-pointer"
            >
              Login | Signup
            </Link>
          </Modal.Body>
      </Modal>

        <Modal show={shownext}>
          <Modal.Header>
            <Modal.Title>{t("bullPassPayment")}</Modal.Title>
            <span
              style={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "50%",
                fontSize: "15px !important",
                padding: "0 6px",
              }}
              onClick={() => setShownext(false)}
            >
              X
            </span>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label"
                >
                  {t("emailAddress")}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label"
                >
                  {t("price")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setShownext(false)}
            >
              {t("submit")}
            </Button>
          </Modal.Footer>
        </Modal>
    {/*Modal*/}


      
      <li
      style={{ cursor: "pointer" }}
      className="list-inline-item mb-0 me-3 rounded-3 border"
    >
      <CartPopover
        trigger={
          <button className="cartBtn" onClick={() => setOpenState()}>
            <BiCartAlt className="fs-3" />{" "}
            <Badge className="rounded-circle" bg="primary">
              {carts.items.length}
            </Badge>
          </button>
        }
        side={"bottom"}
        openState={openState}
        cartItems={carts.items}
      
        onConnectWallet={() => {
          !account && connectAccount();
          account && checkout({ ...buyEndpointOptions })
        }}
      />
    </li>

      </ul>
    
        </div>
      </nav>
    

      {/*mobile Nav*/}
      <div className={`mobileWrap ${toggle ? 'open' : '' }`} >
      <button className={`navbar-toggles ${toggle ? 'open' : '' }`} onClick={handleToggle}>
      <div className="liness">
          <span></span>
          <span></span>
          <span></span>
      </div>
      </button>
    

      {/* nav step*/}
      <div className='mobileNamv' >
        {
          navStep &&
          ( <ul>
           <li className="px-2 d-flex flex-column py-2">
           <Link
             onClick={() => setNavStep(!navStep)}
           >
           <div className="d-flex align-items-center gap-2">
                <BsChevronLeft/>
                <div className="d-flex align-items-center gap-2">
                <img width={30} src={fraPoolImg} alt="" />
                <div>
                  <p className="text-text">Smashpool</p>
                </div>
              </div>
              </div>
        
           </Link>{" "}
           </li>
           <li className="px-2 d-flex flex-column py-2">
           <Link
             to="/rockpool/pools"
             onClick={handleToggle}
           >
             <div className="d-flex align-items-center gap-2">
               <img width={30} src={joinPool} alt="" />
               <div>
                 <p className="text-text">{t("joinPool")}</p>
               </div>
             </div>
           </Link>{" "}
         </li>

         <li className="px-2 d-flex flex-column py-2">
             {/* <CustomOverlay/> */}
        </li>
       <li className="px-2 d-flex flex-column py-2">
       <Link
         to="/crowdsale"
         onClick={handleToggle}
       >
         <div className="d-flex align-items-center gap-2">
           <img width={30} src={joinPool} alt="" />
           <div>
             <p className="text-text">Crowdsale</p>
           </div>
         </div>
       </Link>{" "}
     </li>
         <li className="px-2 d-flex flex-column py-2">
           <Link
               to="/crowdsale/create"
               onClick={handleToggle}
             >
               <div className="d-flex align-items-center gap-2">
                 <img width={30} src={createPool} alt="" />
                 <div>
                   <p className="text-text">
                     {" "}
                     Crowdsale Create
                   </p>
                 </div>
               </div>
             </Link>{" "}
         </li>
         <li className="px-2 d-flex flex-column py-2">
         <Link
           to="/crowdsale"
           onClick={() => setActive("hot")}
         >
           <div className="d-flex align-items-center gap-2">
             <img width={30} src={joinPool} alt="" />
             <div>
               <p className="text-text">Crowdsale</p>
             </div>
           </div>
         </Link>{" "}
       </li>
        {/*
        <li className="px-2 d-flex flex-column py-2">
        <Link
          to="/rockpool/create"
          onClick={() => setActive("hot")}
        >
          <div className="d-flex align-items-center gap-2">
            <img width={30} src={createPool} alt="" />
            <div>
              <p className="text-text">
                {" "}
                {t("fractionalizeNft")}
              </p>
            </div>
          </div>
        </Link>{" "}
      </li>
      */}
           </ul>)
        }

         {/*Language steps*/}
         {
          lanStep && (
            <ul>
            <li style={{cursor:'pointer'}} className="px-2 d-flex py-2" onClick={() => setLanStep(!lanStep)} >
            <span className="mb-0 d-inline-block me-1">
               <BsChevronLeft/>
                 </span>
              Language
            </li>
            <li className="px-2 d-flex flex-column py-1"  onClick={handleJapanese}>
              <a className="" href="#">
                <span className="">
                  JA <span>(日本語)</span>
                </span>
              </a>
            </li>
            <li className="px-2 d-flex flex-column py-1" onClick={handleEnglish}>
              <a className="" href="#">
                <span className="">
                  EN <span>(English)</span>
                </span>
              </a>
            </li>
              
            </ul>
            )
        }

        {/*profile steps*/}
        {
          profileStep && (
            <ul>
            <li className="px-2 d-flex flex-column py-2">
            <Link
              onClick={() => setProfileStep(!profileStep)}
            >
         
            <div className="d-flex align-items-center gap-2">
                <BsChevronLeft/>
                <div className="d-flex align-items-center gap-2">
                <img width={30} src={profileImg2} alt="" />
                <div>
                  <p className="text-text">Profile</p>
                </div>
              </div>
              </div>
            </Link>{" "}
            </li>
            <li className="px-2 d-flex flex-column py-2">
            <Link
              to={`/profile/${account}`}
              onClick={handleToggle}
            >
              <div className="d-flex align-items-center gap-2">
                <img width={30} src={collectionImg} alt="" />
                <div>
                  <p className="text-text">My Collections</p>
                </div>
              </div>
            </Link>{" "}
          </li>
            <li className="px-2 d-flex flex-column py-2">
            <Link
               to={`/profile/${account}`}
               onClick={handleToggle}
            >
              <div className="d-flex align-items-center gap-2">
                <img width={30} src={fraPoolImg} alt="" />
                <div>
                  <p className="text-text">My Fractional</p>
                </div>
              </div>
            </Link>{" "}
          </li>
            <li className="px-2 d-flex flex-column py-2">
            <Link
              to="/profile-edit"
              onClick={handleToggle}
            >
              <div className="d-flex align-items-center gap-2">
                <img width={30} src={settingsImg} alt="" />
                <div>
                  <p className="text-text">Settings</p>
                </div>
              </div>
            </Link>{" "}
          </li>
          <li  className="px-2 d-flex flex-column py-2 border rounded-1">
          <div
            className="small fw-semibold d-flex align-items-center justify-content-center"
            onClick={disconnect}
            style={{ cursor: "pointer" }}
          >
            <span className="mb-0 d-inline-block me-1">
              <i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i>
            </span>
            {t("logOut")}
          </div>
          </li>
             {
              /*
                 <li className="list-inline-item mb-0">
            <div
            style={{ minWidth: 250 }}
          >

            <div className="mt-2">
              <Link
                  className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                  to={`/profile/${account}`}
                  onClick={handleToggle}
                >
                  <span className="mb-0 d-inline-block me-1">
                    <i className="uil uil-cog align-middle h6 mb-0 me-1"></i>
                  </span>
                  {t("myProfile")}
                </Link>
             
              <div className="dropdown-divider border-top"></div>
              <div
                className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                onClick={disconnect}
                style={{ cursor: "pointer" }}
              >
                <span className="mb-0 d-inline-block me-1">
                  <i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i>
                </span>
                {t("logOut")}
              </div>
              <div className="dropdown-divider border-top"></div>
              <Link
                  className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                  onClick={() => setProfileStep(!profileStep)}
                >
                <span className="mb-0 d-inline-block me-1">
                  <AiOutlineArrowLeft/>
               </span>
                 Back
                </Link>
            </div>
          </div>
             </li>
              */
             }
             </ul>
            )
        }
        

        {
          (!navStep & !profileStep & !lanStep) ? (
            <ul>
            {
              account && <li className="px-2 d-flex flex-column py-2">
              <Link
              onClick={()=>{setNavStep(false); setProfileStep(true);setLanStep(false);}}
              >
                <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-2 justify-content-between">
                <img width={24} src={profileImg2} alt="" />
                  Profile
                </div>
                <BsChevronRight/>
              </div>
              </Link>
            </li>
            }
            <li className="px-2 d-flex flex-column py-2">
            <Link to="/trending-collections"
            onClick={handleToggle}
            >
              <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2 justify-content-between">
              <img width={24} src={collectionImg} alt="" />
              {t("collection")}
              </div>
            </div>
            </Link>
          </li>

          <li className="px-2 d-flex flex-column py-2">
          <Link
          onClick={()=>{setNavStep(true); setProfileStep(false);setLanStep(false)}}
          >
            <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 justify-content-between">
            <img width={24} src={fraPoolImg} alt="" />
            Smashpool
            </div>
            <BsChevronRight/>
          </div>
          </Link>
        </li>

            {
              /*
                
        <li className="px-2 d-flex flex-column py-2">
        <Link onClick={handleToggle}>
          <div className="d-flex align-items-center gap-2">
          <img width={24} src={marketplaceImg} alt="" />
          <div>
          {t("marketPlace")}
          </div>
        </div>
        </Link>
      </li>

              */
            }
      

        <li className="px-2 d-flex flex-column py-2">
        <Link
        onClick={()=>{setNavStep(false); setProfileStep(false);setLanStep(true)}}
        >
          <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2 justify-content-between">
          <img width={24} src={globalImg} alt="" />
            Language
          </div>
          <div> 
           <strong>en</strong>
          <BsChevronRight/>
          </div>
        </div>
        </Link>
      </li>
          

    {
      !account && (
        <>
        <li className="px-2 d-flex flex-column py-2 text-center border rounded-2 ">
        <Link onClick={() => setShow(true)}>
          <button >
          {t("connectWallet")}
        </button>
        </Link>
      </li>
        <li className="px-2 d-flex flex-column py-2 text-center border rounded-2 brand-bg">
        <Link to="/login" onClick={handleToggle}>
          <button className="text-uppercase">
                LOGIN | SIGNUP
        </button>
        </Link>
      </li>
        </>
      )
    }

           </ul>
        ) : ""
        }
    {/*test*/}


           

            {/* Set Nav*/}
          
      </div>
    
      </div>
      

          
        </div>
      </header>
    </>


  );
};

export default Navbar;



