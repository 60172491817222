import { notification } from 'antd'
import { useCallback, useEffect } from 'react'
import useLeave from '../collective-crowd-purchase/useLeave'

export default function useSpecificLeavePool(
  chainId,
  specificPoolItem,
  // userParticipation,
  // refetchData
) {
  const { leave, status, dismiss, isLoading, data } = useLeave(chainId, specificPoolItem)
  const handleLeave = async () => {
    try {
      await leave()
    } catch (err) {
      console.log(err)
      console.error({
        type: 'error',
        text: 'error',
        duration: 5
      })
    }
  }

  const notificationSuccessAddFounds = useCallback(() => {
    notification.success({
      message: `Funds successfully removed!`,
      description: ``,
      placement: 'top',
      duration: 2
    })
  }, [])

  useEffect(() => {
    if (status === 'success') {
      // refetchData()
      dismiss()
      notificationSuccessAddFounds()
    }
    if (status === 'reverted') {
      console.error({
        type: 'error',
        text: 'error',
        duration: 5
      })
    }
  }, [status, dismiss, notificationSuccessAddFounds])

  return {
    handleLeave: leave,
    isExecuting: isLoading,
    data,
    status,
  }
}
