import React from "react";
import girl from "../../../assets/girl.png";
import SectionFirst from "./sections/SectionFirst";
import SectionSecond from "./sections/SectionSecond";
import SectionThird from "./sections/SectionThird";
import SectionFourth from "./sections/SectionFourth";
import SectionFifth from "./sections/SectionFifth";


const Banner = ({ setShowFirstModal }) => {
  return (
    <section style={{ marginTop:"100px" }} className="container">
      <div className="px-0 px-lg-5 row gx-4">
        <div className="col-12 col-lg-6 d-none d-lg-block">
          <img
            style={{ objectFit: "cover" }}
            src={girl}
            alt=""
            className="img-fluid w-100 h-100"
          />
        </div>

        <div className="  p-3 rounded mb-3 d-flex d-lg-none align-items-center">
          <div className="d-flex align-items-center gap-2 w-75">
            <div className="w-50">
              <img
                className="w-100"
                src="https://cdn.pixabay.com/photo/2022/01/17/17/20/bored-6945309__340.png"
                alt=""
              />
            </div>
            <div className="w-50">
              <h5 className="fw-bold m-0 p-0">Kidzuki #257</h5>
              <p className="m-0 p-0">Kidzuki Goerli</p>
              <p className="m-0 p-0">Rarity #124</p>
            </div>
          </div>

          <div className="w-25 text-end">
            <h5 className="fw-bold m-0 p-0">3 ETH</h5>
            <p className="m-0 p-0" style={{ fontSize: "12px" }}>
              7 days duration
            </p>
            <p className="m-0 p-0" style={{ fontSize: "12px" }}>
              $5647.68 USD
            </p>
            <p className="m-0 p-0" style={{ fontSize: "12px" }}>
              Total 100 Shares
            </p>
          </div>
        </div>

        <div
          style={{
            color: "#717273",
          }}
          className="col-12 col-lg-6"
        >
          {/* 1st section start */}
          <SectionFirst />
          {/* 1st section end */}
          {/* 2nd section start */}
          <SectionSecond />
          <span className="ms-3" style={{fontSize:15}}>65,000 JPY Total</span>
          {/* 2nd section end */}
          {/* 3rd section start */}
          <SectionThird />
          {/* 3rd section end */}
          {/* 4th section start */}
          <SectionFourth />
          {/* 4th section end */}
          {/* 5th section start */}
          <SectionFifth />
          {/* 5th section end */}
          <div className="row mt-3" onClick={() => setShowFirstModal(true)}>
            <button className="btn btn-primary w-100 fw-bold fs-5 p-2">
              Join Pool
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
