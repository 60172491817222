import React from "react";

const FirstColumn = () => {
  return (
    <div className="col-12">
      <div className="border-bottom mt-4 mb-3">
        {/* <p className="fw-bold">ARTIST INFO</p> */}
        <p className="fw-bold text-end">DESCRIPTION</p>
      </div>
      <div>
        <h6 className="fw-bold mb-3">Banksy</h6>
      </div>
      <div>
        A masked artist who excites people all over the world with his
        mysterious tricks Originally from Bristol, England. His date of birth
        and real name are unknown.
      </div>
      <p className="mb-5 mt-4">Read more +</p>
      <div>
        <p className="m-0 p-0">Description 1</p>
        <p className="m-0 p-0">Silkscreen, 2002</p>
        <p className="m-0 p-0">58.4 x 43.2 cm</p>
        <p className="m-0 p-0">ED, 250</p>
        <p className="m-0 p-0">Edition number/Banksy stamp on bottom</p>
      </div>
      <p className="my-5">Read more +</p>
    </div>
  );
};

export default FirstColumn;
