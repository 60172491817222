import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { GoCreditCard } from "react-icons/go";
import { IoMdWifi, IoMdClose } from "react-icons/io";
import "./Modal.css";
import ApprovePoolModal from "./ApprovePoolModal";
import CongratulationModal from "./CongratulationModal";
import BuyWithCreditModal from "./BuyWithCreditModal";

const AddFundsModal = ({ showFirstModal, setShowFirstModal, handleJoin, saveJoin, data, amount, jpyRate, showCongratulationModal, setShowCongratulationModal }) => {
  const handleClose = () => setShowFirstModal(false);
  console.log(data);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showBuyCreditModal, setShowBuyCreditModal] = useState(false);

  const handleMetaMaskModal = () => {
    setShowFirstModal(false);
    setShowApproveModal(true);
  };

  const handleBuyCredit = () => {
    setShowFirstModal(false);
    setShowBuyCreditModal(true);
  };

  const handleApproveButton = async () => {
    // await handleJoin();
    // await saveJoin();
    setShowFirstModal(false);
    setShowApproveModal(true);
    // setShowCongratulationModal(true);
  };

  return (
    <>
      <Modal
        show={showFirstModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className="p-3 border rounded border-primary">
          <Modal.Header closeButton className="border-0">
            <Modal.Title className="w-100">
              <div className="d-flex justify-content-between w-100">
              <p className="fw-bold m-0 p-0 ">Add Funds</p>
              {/* <button variant="outline-link" className="btn" onClick={handleClose}><IoMdClose /></button> */}
              </div>
              <p style={{ fontSize: "14px" }} className="fw-normal m-0 p-0">
                You are adding {amount ?? 0} ETH in participation. Do you want to add it?
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="border border-primary p-3 rounded mb-3 d-flex gap-3 align-items-center hover-effect"
              onClick={handleBuyCredit}
              // onClick={handleJoin}
            >
              <div className="text-primary fs-1 p-2">
                <GoCreditCard />
              </div>
              <div>
                <h5 className="m-0 p-0 fw-bold">Credit / Dedit / Bank Trnsfer</h5>
                <p style={{ fontSize: "14px" }} className="m-0 p-0">
                  Add funds with Mastercard or Visa
                </p>
              </div>
            </div>
            <div
              className="border border-primary p-3 rounded mb-3 d-flex gap-3 align-items-center hover-effect"
              onClick={handleApproveButton}
            >
              <div className="text-primary fs-1">
               <img width={55} src="https://testnet.fra-art.com/static/media/metamask.07b64450a967a6efa1272f84c34c0d70.svg" alt="" />
              </div>
              <div>
                <h5 className="m-0 p-0 fw-bold">MetaMask</h5>
              </div>
            </div>
            {/* <div className="border border-primary p-3 rounded mb-3 d-flex gap-3 align-items-center hover-effect">
              <div className="text-primary fs-1 p-2">
                <IoMdWifi />
              </div>
              <div>
                <h5 className="m-0 p-0 fw-bold">Wallet Connect</h5>
              </div>
            </div> */}
            {/* <div className="btn btn-primary w-100 fw-bold mb-2 p-3">
              ADD FUNDS
            </div> */}
            <div
              className="btn btn-outline-primary w-100 fw-bold p-3"
              onClick={() => setShowFirstModal(false)}
            >
              CANCEL
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <ApprovePoolModal
        showApproveModal={showApproveModal}
        setShowApproveModal={setShowApproveModal}
        setShowFirstModal={setShowFirstModal}
        setShowCongratulationModal={setShowCongratulationModal}
        handleJoin={handleJoin}
        data={data}
        amount={amount}
        jpyRate={jpyRate}
        saveJoin={saveJoin}
      />
      <CongratulationModal
        showCongratulationModal={showCongratulationModal}
        setShowCongratulationModal={setShowCongratulationModal}
        data={data}
      />
      <BuyWithCreditModal
        showBuyCreditModal={showBuyCreditModal}
        setShowBuyCreditModal={setShowBuyCreditModal}
        setShowCongratulationModal={setShowCongratulationModal}
        data={data}
        amount={amount}
        jpyRate={jpyRate}
      />
    </>
  );
};

export default AddFundsModal;
