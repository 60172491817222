import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import '../pages/homestyle.css';
import icon1 from "../assets/images/icons/3.png"
import icon2 from "../assets/images/icons/1.png"
import OutlinedInput from '@mui/material/OutlinedInput';
import icon3 from "../assets/images/icons/6.png"
import mark from '../assets/images/icons/mark.png'
import girl from '../assets/images/icons/girl.png';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Textarea from '@mui/joy/Textarea';
import CloseIcon from '@mui/icons-material/Close';
import IosShareIcon from '@mui/icons-material/IosShare';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { notFoundImg } from '../utils/images.util';
import InputRange from 'react-input-range';
import { ethers, utils } from 'ethers';
import axios from 'axios';
import { chainConfig } from '../hooks/ChainConfig';
import { useAccount, useNetwork } from 'wagmi';
import useSpecificCreatePool from '../hooks/rockpool/specific/useSpecificCreatePool';
import perpetualOpenCollectivePurchaseAbi from "../hooks/rockpool/open-collective-purchase/openCollectivePurchaseAbi.json";
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useListings, useTokens } from '@reservoir0x/reservoir-kit-ui';

const today = dayjs();
const tomorrow = dayjs().add(1, 'day');

function OverlayComponent({open, setOpen, contractAddress, tokenId, children}) {
  const navigator = useNavigate()
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [fee, setFee] = useState(0.1);

  const handleClick = () => {
    setShowOverlay(!showOverlay);
  }
  const [showOverlayOne, setShowOverlayOne] = useState(false);

  const handleClicks = () => {
    setShowOverlayOne(!showOverlayOne);
    setShowOverlay(false);
    setShowOverlayTwo(false);
    navigator("/rockpool/pools")
  }
  const [showOverlayTwo, setShowOverlayTwo] = useState(false);

  const handleClickss = () => {
    setShowOverlayTwo(!showOverlayTwo);
    setShowOverlay(false);
  }
  const isMobile = useMediaQuery('(max-width: 600px)');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : 540,
    height: isMobile ? '100%' : '90%',
    borderRadius: '15px',
    bgcolor: 'white',
      border: '2px solid #fff',
    boxShadow: 24,
    p:  isMobile ? 1 : 2,
  };
    const styleOne = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '100%' : 530,
      height: isMobile ? '100%' : '70%',
      borderRadius: '15px',
      bgcolor: 'white',
      //   border: '2px solid #000',
      boxShadow: 24,
      p:  isMobile ? 1 : 3,
    };
    const styleS = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '100%' : 550,
      height: isMobile ? '100%' : '70%',
      borderRadius: '15px',
      bgcolor: 'white',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 3,
    };

    const handleChange = (e) => {
      const key = e.target.name
      const value = e.target.value
      console.log(key);
      switch (key) {
        case "fractionName":
          setFractionName(value)
          break;
        case "fractionSymbol":
          setFractionSymbol(value)
          break;
        case "priceMultiplier":
          setPriceMultiplier(value)
          break;
        case "fractionExtension":
          setExtension(value)
          break;
        case "fractionFee":
          setFee(value)
          break;
        default:
          break;
      }
    }
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [calling, setCalling] = useState(false);
    let [Collection, setCollection] = useState("");
    const [fractionName, setFractionName] = useState(null);
    const [fractionSymbol, setFractionSymbol] = useState(null);
    const [priceMultiplier, setPriceMultiplier] = useState(null);
    const [extension, setExtension] = useState(null);
    const [refetch, setRefetch] = useState(0);
    const [creating, setCreating] = useState(false);
    const [pvtPoolChecked, setPvtPoolChecked] = useState(false);
    const [curatorsFee, setCuratorsFee] = useState(0);
    const [openOne, setOpenOne] = React.useState(false);

    const createValid = Boolean(fractionName)&&Boolean(fractionSymbol)&&Boolean(priceMultiplier)&&Boolean(Number(extension));
    const loading = creating;

    const handleOpenOne = () => {
      handleClose();
      setOpenOne(true);

    };
    const handleCloseOne = () => setOpenOne(false);
  const { chain: activeChain } = useNetwork();
  const { products } = chainConfig(activeChain?.id)
	const { address, isConnected } = useAccount()
  const walletChainId = activeChain?.id;

  const { data: tokens } = useTokens({
    tokens: [`${contractAddress}:${tokenId}`],
  });

  const { data: listingDatas } = useListings({
    token: `${contractAddress}:${tokenId}`,
    contract: contractAddress,
    source: 'opensea.io'
  });
  console.log(listingDatas, "listingDatas");

  const image_url = tokens?.length ? tokens[0].token.image : notFoundImg;

    const [openTwo, setOpenTwo] = React.useState(false);
    const handleOpenTwo = () => {
      handleCloseOne();
      setOpenTwo(true);

    };
    const handleCloseTwo = () => setOpenTwo(false);

    const type = 'AUCTION'
    const types = ['bytes32', 'string', 'string', 'uint256', 'uint256'];
    const values = [utils.formatBytes32String(type), fractionName??"", fractionSymbol??"", Number(extension??0) * 86400, utils.parseEther((Number(fee??0)/100).toFixed(18))];
  
    const extra = utils.defaultAbiCoder.encode(types, values)
    const { handleCreate, isExecuting, isSuccess, data: createData, status: createStatus } = useSpecificCreatePool(contractAddress, tokenId, priceMultiplier, extra, walletChainId)

    const createPool = async () => {
      // const openseaData = await fetch(link)
      // console.log("openseaData",openseaData)
      
      await handleCreate()
      console.log("isSucess",isSuccess)
    }
    
    const fetchData=async(item)=>{
      const query = `${process.env.REACT_APP_BASE_URL}/api/createRockPool`
      axios.post(query, item).then(res => {
        console.log("res1", res)
      }).catch(err => { })
    }
  
    const saveData=async()=>{
      const provider = new ethers.providers.InfuraProvider(
        "goerli",
        "e5909f511a3f4297b5bfdc84f08dcb45"
        );
      if(isSuccess){
        console.log("this is the test")
        console.log(Collection)
        const contractErc20Fraction = new ethers.Contract(products.specific.contract.openCollectivePurchase, perpetualOpenCollectivePurchaseAbi, provider);
        const item = {
          amount: 0,
          fee:0,
          fractions:0,
          fractionsCount:0,
          contract: contractAddress,
          tokenId,
          priceMultiplier,
          image: Collection?.image_url,
          owner: address,
          isErc721Available:true,
          listingId:parseInt(await contractErc20Fraction.listingCount()) - 1,
          price:(Collection?.collection?.stats?.average_price).toString(),
          status:true,
          reservePrice: listingDatas?.length?listingDatas[0].price.amount.decimal:0,
          targetPrice: listingDatas?.length?listingDatas[0].price.amount.decimal:0,
          title:Collection?.name == null ? Collection?.asset_contract?.name: Collection?.name,
          progress: 0,
          userParticipation:0,
          kickoff: listingDatas?.length&&listingDatas[0].validFrom,
          duration: Number(listingDatas?.length&&listingDatas[0].validUntil) - Number(listingDatas?.length&&listingDatas[0].validFrom)
      }
  
        await fetchData(item)
      }else{
        return console.log()
      }
    }
  
    useEffect(()=>{
      const callOpensea = async () => {
        setCalling(true)
        const link = `https://testnets-api.opensea.io/api/v1/asset/${contractAddress}/${tokenId}?format=json`;
        await fetch(link)
        .then(res =>  res.json())
        .then((data) => {
          if(data) setCollection(Collection = data)
          setCalling(false)
        })
        .catch(e => {
          setCalling(false)
        })
      }
      if(contractAddress && tokenId && !calling){
        callOpensea()
      }
    },[contractAddress, tokenId])
    

      useEffect(()=>{
        if(createStatus === "loading") {
          setCreating(true);
        } else if(createStatus !== "success") {
          setCreating(false);
        }
      },[createStatus])
    
      useEffect(() => {
        const update = async () => {
          if (createData?.hash) {
            console.log("createData", createData);
            const result = await createData.wait(1)
            console.log("waitresult:", result);
            if(result?.transactionHash) {
              saveData()
              .then(() => {
                setRefetch(prev => prev+1)
                setCreating(false)
                handleOpenTwo()
              })
              .catch(e => {
                setRefetch(prev => prev+1)
                setCreating(false)
              })
            }
            else {
              setCreating(false)
    
            }
          }
        }
        update()
      }, [createData])
    
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="flex relative">

<div className="overlay-content">


  <div className="row mb-3 padding-ov">
  <div className=' cross mb-3 text-right'>  <CloseIcon className='close' onClick={handleClose} ></CloseIcon></div>


    <div className='text-center mb-3'>
      <img src={Collection?.image_url ?? image_url} alt={contractAddress} style={{borderRadius:"15px"}} className="girl" />

    </div>
    <div className='mb-3'> 
    <p className='md text-center my-2' style={{color:'#666A6D'}}>{t("buyAnyNFTListeOnOpenSeaAt")}</p>
    {/* <input type="text" value={Collection.eth_price} className="form-control input-font-ui border-ui input-number py-2" /> */}
    {/* <p className='sm text-left className="mb-5' style={{color:'#666A6D',fontSize:9}}>Enter OpenSea listing at a fixed price.</p> */}

    </div>

    <div className='mb-3' >
      <div className=" border-ui mb-3">
        <div className= {`${isMobile ? '  d-flex around ' : 'row'}`}>
          <div className={`${isMobile ? '' : 'col-md-4 text-left'}`}>
            <span className="sm-s sm "><img src={icon1} height={10} alt="" />Set Fraction Name </span>
            <input type="text" className="form-control padding border-ui  input-font-ui"
              name='fractionName'
              value={fractionName}
              onChange={handleChange} />
          </div>
          <div className={`${isMobile ? '' : 'col-md-4 text-left'}`}>
            <span className="sm-s sm "><img src={icon2} height={10} alt="" />Set Fraction Symbol </span>
            <input type="text" className="form-control padding border-ui  input-font-ui"
              name='fractionSymbol'
              value={fractionSymbol}
              onChange={handleChange} 
            />
          </div>
          <div className={`${isMobile ? '' : 'col-md-4 text-left'}`}>
            <span className="sm-s sm"><img src={icon3} height={10} alt="" />Price Multiplier</span>
            <input type="text" className="form-control padding border-ui  input-font-ui"
              name='priceMultiplier'
              value={priceMultiplier}
              onChange={handleChange} />
          </div>

        </div>


    </div>

      {/* <p className="md bold-lg text-left ">Duration</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker', 'DatePicker']}>
          <DatePicker label="Start" defaultValue={dayjs('2022-04-17')} />
          <DatePicker
            label="End"
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </DemoContainer>
      </LocalizationProvider>   */}
      <p className="md bold-lg text-left">Pool Duration(Days)</p>
      <input
        className="form-control padding border-ui  input-font-ui"
        name='fractionExtension'
        onChange={handleChange}
        value={extension}
      />
      {/* <p className="md bold-lg text-left mt-2">Fraction fee(%)</p>
      <input
        className="form-control padding border-ui  input-font-ui"
        name='fractionFee'
        onChange={handleChange}
        value={fee}
      /> */}
      <div className='hstack'>
        <input
          className='form-check-input mt-0'
          type='checkbox'
          value={pvtPoolChecked}
          onChange={(e) => setPvtPoolChecked(e.target.checked)}
          id='pvtPool'
        />
        <label
          className='form-check-label text-muted ms-2'
          htmlFor='pvtPool'>
          Private Pool (not listed on the Marketplace)
        </label>
      </div>
      {pvtPoolChecked && (
        <div className='mb-4 mt-3'>
          <label className='form-label'>Curator's Fees</label>
          <InputRange
            maxValue={20}
            minValue={0}
            formatLabel={(v) => `${v}%`}
            value={curatorsFee}
            onChange={(v) => setCuratorsFee(v)}
          />
        </div>
      )}
    </div>
    <div>
      <button onClick={handleOpenOne} disabled={creating || !createValid} className="btn btn-ui rounded-md w-100 mb-3 " >
        CREATE POOL
      </button>
      <button onClick={handleClose} className="btn btn-ui-muted rounded-md w-100 mb-3" >
        Cancel
      </button>
    </div>
  </div>
</div>
   
</Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openOne}
        onClose={handleCloseOne}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openOne} className="flex">
          <Box sx={styleOne} className="relative">


<div className="row  padding-ov">
<div className=' cross mb-3 text-right'>  <CloseIcon className='close' onClick={handleCloseOne} ></CloseIcon></div>

  <div className=' mb-3'> 
    <p className='font-ui-cl padding-top' >APPROVE POOL</p>
  </div>

  <div className=" mb-5 d-flex between ">
                <div className='d-flex between'>
                    <img src={Collection.image_url??image_url} alt={contractAddress} style={{ height: '70px', borderRadius: '10px' , margin:'10px' }} />

                  <div className="m-t">
                    <p className="md bold-lg">{Collection?.name == null ? Collection?.asset_contract?.name: Collection?.name}</p>
                    <p className="sm">rarity #{Collection?.rarity_data?.rank ?? "No"} </p>
                  </div>
              </div>
              <div className="">
              <p className="md ">{Collection.eth_price} ETH</p>

              <p className="sm">{extension} days extension</p>
                <p className="sm">${Collection?.collection?.payment_tokens[0]?.usd_price} USD</p>
              </div>
            </div>
            <hr />
  <div className='mb-5'>
    <p className='bold-md'>Go to your wallet
</p>
<p className='sm'>You'll be asked to approve this pool from your wallet.</p>
  </div>


  <div className="mb-3">
  <button onClick={createPool} disabled={creating || !createValid} className="btn btn-ui w-100 mt-3" >
    {creating?<CircularProgress color="secondary" size={25} />:"Approve"}
  </button>
  <button onClick={handleCloseOne} className="btn btn-ui-muted w-100 mt-3" >
    Cancel Pool
  </button>


  </div>


</div>
</Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openTwo}
        onClose={handleCloseTwo}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openTwo}>
        <Box sx={styleOne} className="relative flex">


<div className="row  padding-ov">
<div className=' cross mb-3 text-right'>  <CloseIcon className='close' onClick={handleCloseTwo} ></CloseIcon></div>

          <p className='text-center md-lg bold-md mb-3 mbm-5'>Congratulations!</p>
          <div className='text-center mb-3'>
            <img src={Collection.image_url??image_url} alt="girl" className="girl bd-radius mb-3" />

          </div>
          <div className="ow mb-3 mbm-5">
            <p className='md  text-center bold-lg mb-3 '> <span><img src={mark} width={30} alt="" /></span> Your pool is complete & live now</p>

          </div>
          <div className="mb-5 text-center">
            <span className='md border-ui text-center bold-lg mb-5 '>Pool ID</span>

          </div>
          <div className='d-flex text-center w-100 ui-icon  mb-3 mbm-5'>
          <i className=" uil-facebook-f"></i>
                <i className=" uil-instagram"></i>


                <i className=" uil-twitter"></i>
                <i className=" uil-copy-link"></i>

          </div>


          <div>
            <button onClick={handleClicks} className="btn btn-ui rounded-md w-100" >
              View  POOL listing
            </button>


          </div>


        </div>
</Box>
        </Fade>
      </Modal>
    </div>

  );
}
export default OverlayComponent;
