import React, { useState, useEffect, useContext } from "react";
import { Navigate,Outlet } from 'react-router-dom'
import { AuthStateContext } from "../context/authContext";
import { useWeb3React } from "@web3-react/core";

export default function PrivateRoute() {
  
    const { authTkn } = useContext(AuthStateContext);
    const [autheticateToken, setAuthToken] = useState(null);
    const { account } = useWeb3React();
 
    useEffect(() => {
      let authToken = localStorage.getItem('authToken')
      setAuthToken(authToken)
    }, []);
  
  return (
    (account || autheticateToken||authTkn)  ? <Outlet/> : <Navigate to='/login'/>
  )
}
