import { useEffect, useState } from 'react'
import { usePrepareContractWrite, useSendTransaction } from 'wagmi'
import { chainConfig } from '../../ChainConfig'
import externalAcquirerAbi from './externalAcquirerAbi.json'
import seaportAbi from './seaportAbi.json'
import { ethers } from 'ethers'
import axios from 'axios'
const useAcquire = (chainId, specificPoolItemId, listed, poolIsCompleted, Collection) => {
  const [functionParams, setFunctionParams] = useState(null)
  let [calldata, setCallData] = useState(null)
  // Create an instance of the contract interface
  const contractAddress = '0x00000000000000adc04c56bf30ac9d3c0aaf14dc'; // Replace with the actual contract address
  const contractAbi = seaportAbi; // Replace with the actual contract ABI
  // const provider = new ethers.JsonRpcProvider('https://goerli.infura.io/v3/8ca754ed5afb406da53f5b58b462a679'); // Replace with your Ethereum provider

  // Prepare the calldata for the function call
  const functionName = 'fulfillBasicOrder'; // Replace with the actual function name

  const contract = new ethers.Contract(contractAddress, contractAbi);

  useEffect(() => {
    if(functionParams)
      setCallData(calldata = contract.interface.encodeFunctionData(functionName, functionParams));
  }, [functionName, functionParams])

  const { products } = chainConfig(chainId)
  const { config } = usePrepareContractWrite({
    address: products.specific.contract.externalAcquirer,
    abi: externalAcquirerAbi,
    functionName: 'acquire',
    args: [specificPoolItemId, true, calldata]
  })
  const { sendTransaction, isSuccess, data, isLoading, status } = useSendTransaction(config)
  // const { status, dismiss } = useObserverTransaction(data, isSuccess, products.specific.subgraph)
  const acquire = async () => {
    if (calldata && sendTransaction) {
      await sendTransaction()
    }
  }

  const order = Collection?.orders?.length > 0 ? Collection.orders[0]:null;
  useEffect(() => {
    if(order) {
      const res = axios.post(`https://testnets-api.opensea.io/v2/listings/fulfillment_data`, {
        "listing": {
          "hash": order.order_hash,
          "chain": "goerli",
          "protocol_address": "0x00000000006c3852cbEf3e08E8dF289169EdE581"
        },
        "fulfiller": {
            "address": contractAddress
        }
      })
      setFunctionParams(res.data.fulfillment_data.transaction.input_data.parameters)
    }
  }, [order])

  return {
    acquire,
    acquirable: Boolean(sendTransaction),
    isLoading: isLoading,
    data,
    status
  }
}

export default useAcquire
