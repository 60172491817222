import React from "react";
import './SectionFirst.css';



const SectionThird = ({
  FractionIcon,
  fraction,
  UserIcon,
  participant,
  TimeIcon,
  time
}) => {
  return (
    <div  style={{ border: "1px solid #0d6efd",marginLeft:'-10px' }} className="rounded-2  mt-2 p-2 align-items-center section_third_text first-section-text w-100">
      <div className="fraction_items">
        <p style={{ fontSize:"10px",fontWeight:400 }} className="mb-2 p-0"> <img src={FractionIcon} width={15} /> Remaining/ Total Shares</p>
        <p
          className="rounded-2 mt-1 fw-bold"
          style={{
            backgroundColor: "#e9f2ff",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {fraction}/100
        </p>
      </div>
      <div className="fraction_items">
        <p style={{ fontSize:"10px",fontWeight:400 }} className="mb-2 p-0"> <img src={UserIcon} width={15} /> Participants</p>
        <p
          className="rounded-2 mt-3 mt-lg-1 fw-bold"
          style={{
            backgroundColor: "#e9f2ff",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {participant}
        </p>
      </div>
      <div className="fraction_items">
        <p  style={{ fontSize:"10px",fontWeight:400 }} className="mb-2 p-0"> <img src={TimeIcon} width={15} /> Remaining time</p>
        <p
          className="rounded-2 mt-3 mt-lg-1 fw-bold"
          style={{
            backgroundColor: "#e9f2ff",
            padding: "8px",
            textAlign: "center",
          }}
        >
          {isNaN(time)?0:time}
        </p>
      </div>
    </div>
  );
};

export default SectionThird;
