import React from "react";

const SecondColumn = () => {
  return (
    <div className="col-12">
      <div className="border-bottom mb-4">
        {/* <p className="fw-bold">ACTIVITY</p> */}
        <p className="fw-bold text-end">Ownership %</p>
      </div>

      {[0, 1, 2, 3, 4, 5].map((i) => (
        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-3">
          <div className="d-flex align-items-center gap-2">
            <div
              className="bg-danger"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "100%",
              }}
            ></div>
            <div>
              <h6>
                Cashed by{" "}
                <span className="text-primary m-0 p-0">valueflow.eth</span>
              </h6>
              <p className="m-0 p-0">Jan 15, 2023 at 1.09 PM</p>
            </div>
          </div>
          <div>
            <h6 className="m-0 p-0">0.00</h6>
            <p className="m-0 p-0">&#x24; 2.25</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SecondColumn;
