import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { formatNum1 } from "../utils";
import { bg1 } from "../utils/images.util";
import "./trending.css";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { AuthStateContext } from "../context/authContext";

const CollectionHome = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const [amount, setAmount] = useState(50);
  const [noItems, setNoItems] = useState(false);
	const { setUserId } = useContext(AuthStateContext);
  

	const [collections, setCollection] = useState([]);
	const [newCollections, setNewCollections] = useState([]);
	const [allNewCollections, setAllNewCollections] = useState([]);
	const [dataSort, setDataSort] = useState("1h");

	const [load, setLoad] = useState(20);
	const [show, setShow] = useState(true);

	const handleChange = (event) => {
		const searchValue = event.target.value;

		const searchedData = allNewCollections.filter((item) => {
			const searchTerm = searchValue.toLocaleLowerCase();
			const collectionName = item.collection?.name.toLocaleLowerCase();
			return searchTerm && collectionName.startsWith(searchTerm);
		});

		if (searchValue.length > 0) {
			setNewCollections(searchedData);
		} else {
			setNewCollections(allNewCollections);
		}

		console.log(searchedData);
	};

	useEffect(() => {
		const options = {
			method: 'GET',
			headers: {accept: '*/*', 'x-api-key': 'c8c99b47-ac0e-5677-915a-1f0571480193'}
		  };
		fetch(`https://api-goerli.reservoir.tools/collections/v5`,options)
			.then((res) => res.json())
			.then((data) => setCollection(data.collections));
	}, []);

	useEffect(() => {
		const nftGoOptions = {
			method: 'GET',
			headers: {
			  accept: 'application/json',
			  'X-API-KEY': '7d043c4a-eb15-45bb-98ee-5b4392e9a821'
			}
		  };
		fetch(
			`https://api.nftgo.io/api/v1/ranking/trending-collections?sortby=saleNum&asc=-1&offset=0&limit=100&range=${dataSort}`,nftGoOptions
		)
			.then((res) => res.json())
			.then((data) => {
				setNewCollections(data?.data?.list);
				setAllNewCollections(data?.data?.list);
			});
	}, [dataSort]);

	const handleLoadMore = () => {
		setLoad(load + 20);
	};
	useEffect(() => {
		if (load >= collections.length) {
			setShow(false);
		} else {
			setShow(true);
		}
	}, [load]);

	// console.log("okCollection", collections);
	const lastIndex = page * amount;
	const firstIndex = lastIndex - amount;
	const currentLinks = newCollections.slice(firstIndex, lastIndex);

	console.log(firstIndex, lastIndex, "1st last");

	return (
		<div>
			<div className='trending_section container-fluid  '>
				{/* <p
          style={{ fontSize: "24px", fontWeight: "700" }}
          className="text-start  py-5  headingcss'"
        >
          {t("trending")}
        </p> */}
				{/* nav */}
				<div
					style={{ fontSize: "16px", fontWeight: "600" }}
					className='d-flex gap-4  mb-3'></div>
				{/* nav */}

				{/* data sorting navbar start */}
				<div className='d-flex justify-content-between'>
					<div className='trending_btn'>
						<Link
							style={{ fontSize: "20px" }}
							to='/trending'
							className={`d-block`}>
							<Button className='col-12 text-left d-block ' variant='primary'>
								{t("trending")}
							</Button>
						</Link>
					</div>
				</div>
				{/* data sorting navbar end */}

				<div className='mt-1 '>
					<div className='table_section'>
						<table className='table caption-top shadow rounded'>
							<thead>
								<tr
									className=''
									style={{
										fontSize: "14px",
										fontWeight: "700",
										color: "#111119",
									}}>
									{/* <th scope="col">#</th> */}
									<th scope='col' className='tablefix'>
										{t("collection")}
									</th>
									<th className='text-end' scope='col'>
										{t("flootPrice")}
									</th>
									<th className='text-end' scope='col'>
										{" "}
										{t("volume")}({dataSort})
									</th>
									<th className='text-end' scope='col'>
										{" "}
										{t("sales")}({dataSort})
									</th>
									<th className='text-end' scope='col'>
										{t("holers")}
									</th>
									<th className='text-end' scope='col'>
										{t("listed")}
									</th>
								</tr>
							</thead>
							{/* <tbody>
              {collections
                // .slice(0, load)
                .sort((a, b) => b.floorSale["1day"] - a.floorSale["1day"])
                .map((collection, index) => (
                  <tr key={index} className="pointer hover-background">
                    <th scope="row">{index + 1}</th>
                    <td
                    // onClick={() => setUserId(collection?.primaryContract)}
                    >
                      <Link to={`/trending/${collection?.primaryContract}`}>
                        <img
                          width={50}
                          height={50}
                          className="rounded-circle  me-4"
                          src={collection.image}
                          alt=""
                        />
                        {collection.name}
                      </Link>{" "}
                    </td>
                    <td>
                      {" "}
                      {(collection.volume["allTime"] / 1000).toFixed(2)} k{" "}
                      <br />{" "}
                      <span className="text-success">
                        {" "}
                        {(
                          (collection.volume["30day"] -
                            collection.volume["1day"]) /
                          100
                        ).toFixed(2)}{" "}
                        %
                      </span>
                    </td>
                    <td>
                      {" "}
                      <FaEthereum />
                      {collection.floorAsk?.price?.amount?.decimal.toFixed(
                        2
                      )}{" "}
                    </td>
                    <td className="d-flex flex-column ">
                      <p className="mb-0">
                        {collection.floorSale["1day"].toFixed(2)}
                      </p>

                      <p className="mb-0">
                        <span
                          className={
                            collection.floorSale["30day"] -
                              collection.floorSale["1day"] >=
                            0
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {" "}
                          {(
                            (collection.floorSale["30day"] -
                              collection.floorSale["1day"]) *
                            100
                          ).toFixed(1)}{" "}
                          %
                        </span>
                      </p>
                    </td>
                    <td>{collection.tokenCount}</td>
                  </tr>
                </thead>

                {/* collection list */}
                <tbody>
                  {collections?.map((col, idx) => {
                    return (
                      <tr key={idx} className="pointer hover-background">
                        <th style={{ fontSize: "14px" }} scope="row">
                          {idx + 1}
                        </th>
                        <td
                          // onClick={() => setUserId(collection?.primaryContract)}
                          style={{ width: "305px" }}
                        >
                          <Link to={`/trending/${col.id}`}>
                            <div className="position-relative">
                              <img
                                src={col.image}
                                className="avatar avatar-md-sm rounded-pill shadow-sm img-thumbnail"
                                alt=""
                              />
                              <strong className="text-dark h6 ps-3">
                                {col.name}
                              </strong>
                            </div>
                          </Link>{" "}
                        </td>
                        <td className="text-end">
                          <div className="d-flex justify-content-end">
                            {col.sampleImages.map((pic) => (
                              <div className="me-2">
                                <img
                                  className="rounded-3"
                                  width={50}
                                  src={pic}
                                  alt=""
                                />
                              </div>
                            ))}
                          </div>
                        </td>
                        <td className="text-end">
                          {" "}
                          {formatNum1(col.volume["1day"])}{" "}
                        </td>
                        <td className="text-end">
                          {" "}
                          {formatNum1(col.floorAsk.price?.amount?.decimal)}
                        </td>
                        <td className="text-end">{col.tokenCount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="mt-4 text-center"
            // style={{ display: noItems ? "none" : "" }}
          >
            <button
              className="btn btn-pills btn-primary mx-1"
              onClick={() => handleLoadMore()}
            >
              {load ? "Loading..." : "Load more"}
            </button>
          </div>
        </div>
     
    </div>
  );
};

export default CollectionHome;
