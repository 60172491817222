import { CONNECT_MODAL_SHOW } from './type';

export const showModal = (value) => {
  return {
    type: CONNECT_MODAL_SHOW,
    payload: value
  };
};

export const showConnectModal = (value) => {
  return async (dispatch) => {
    dispatch(showModal(value));
  }
};
