import React from "react";
import { HiCurrencyYen } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";
import { TbCircleFilled } from "react-icons/tb";
import "./SectionFirst.css";
import CircleProgress from "../../../CircleProgress";

const SectionFirst = ({
  amount,
  price,
  progress,
  targetEth
}) => {
  return (
    <div style={{ border: "1px solid #0d6efd",marginTop:'-10px' }} className=" rounded-2 row p-2 w-100 ">
      <div className="col-8">
        {/* 1st row */}
        <div className="d-flex flex-row justify-content-between mb-2 first-section-text">
          <p style={
            {
              fontSize:11,
              fontWeight:500,
              color:'#666a6d'
            }

          }>
            <HiCurrencyYen className="me-1" /> Current amount reached 
          </p>
          <p className="d-flex align-items-center" style={{fontSize:14,color:'#000',fontWeight:600}}>
            {amount} <GiHamburgerMenu className="ms-1" />
          </p>
        </div>
        {/* 1st row */}
        {/* 2nd row */}
        <div
          className="rounded-2 p-1 text-center"
          style={{ border: "1px solid #e9f2ff" }}
        >
          <h5
            className="d-none d-lg-block rounded-2 m-0"
            style={{ backgroundColor: "#e9f2ff", color:"inherit",fontSize:20}}
          >
            {price} JPY
          </h5>
          <p
            className="mb-0 d-lg-none"
            style={{ backgroundColor: "#e9f2ff", color: "inherit", fontSize:15}}
          >
            {" "}
            {price ?? 0} JPY
          </p>
        </div>
        {/* 2nd row */}

        {/* 3rd row */}
        {progress}
        <div className=" d-none d-lg-flex gap-1 my-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13,14,15,16,17,18,19,20].map((i) => (
            <div
              key={i}
              className={`${progress > 0 && i < (0.2 * Math.trunc(progress))? "bg-primary" : "progress_box"}   rounded-1  `} //10/100*80
              style={{ height: "24px", width: "15px" }}
            ></div>
          ))}
        </div>
        {/* <div className=" d-flex d-lg-none gap-1 justify-content-center mt-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13,14,15,16,17,18,19,20].map((i) => (
            <div
              className={`${i >= (0.2* Math.trunc(progress))? "progress_box" : "bg-primary"}   rounded-1  `}
              style={{ height: "24px", width: "15px" }}
            ></div>
          ))}
        </div> */}
        {/* 3rd row */}
        {/* 4th row */}
        <div className="d-flex flex-row align-items-center justify-content-between mt-2 first-section-text">
          <p style={{ fontSize:10,fontWeight:700,color:'#666a6d' }} className="mb-0">
            The target amount is {price ?? 0} JPY
          </p>
          <p  style={{ fontSize: 14,color:'#000',fontWeight:600 }}  className="mb-0 d-flex align-items-center">
            {targetEth} <GiHamburgerMenu />
          </p>
        </div>
        {/* 4th row */}
      </div>
      <div className="col-4">
        <div className="d-flex flex-column align-items-center gap-2">
          <div className="active_text" style={{fontSize:10,color:'#666a6d',fontWeight:500}}>
            <TbCircleFilled className="fs-6" style={{ color: "green" }} />{" "}
            Active Pool
          </div>
          {/* <div
            style={{
              border: "3px dashed #cbd5d8",
              borderRadius: "100%",
              padding: "8px",
            }}
          >
            <div
              style={{
                height: "120px",
                width: "120px",
                backgroundColor: "#cbd5d8",
                borderRadius: "100%",
              }}
              className="d-flex justify-content-center align-items-center progress_circle"
            >
              <h2 className="d-none d-lg-block">42%</h2>
              <p className="d-lg-none mb-0">42%</p>
            </div>
          </div> */}
          <CircleProgress progress={progress} />
        </div>
      </div>
    </div>
  );
};

export default SectionFirst;
