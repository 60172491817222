import React, { useState } from 'react'
import Banner from '../components/FraNew/Banner/Banner';
import Info from '../components/FraNew/Info/Info';
import AddFundsModal from '../components/FraNew/Modals/AddFundsModal';

export default function FraaNew() {
  const [showFirstModal, setShowFirstModal] = useState(false);
  return (
    <>
    <Banner setShowFirstModal={setShowFirstModal}/>
    <Info/>
    <AddFundsModal showFirstModal={showFirstModal}
        setShowFirstModal={setShowFirstModal}/>
    </>
  )
}
