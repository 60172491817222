import { combineReducers } from 'redux';
import LineChart from './lineChart';
import DoughnutChart from './doughnutChart';
import LineInvestment from './lineInvestment';
import FirstUpdate from './firstUpdate';
import ConnectModal from './commonState';


export default combineReducers({
    LineChart,
    DoughnutChart,
    LineInvestment,
    FirstUpdate,
    ConnectModal
});
