import { usePrepareContractWrite, useSendTransaction } from 'wagmi'
import { chainConfig } from '../../ChainConfig'
import collectivePurchaseAbi from './collectivePurchaseAbi.json'
import ERC721Abi from '../../../contracts/ERC721.json'
import { ethers } from 'ethers'

const useUnapprove = (contractAddress, tokenId, chainId) => {
  const { products, nativeToken } = chainConfig(chainId)
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: ERC721Abi,
    functionName: 'approve',
    chainId,
    args: [nativeToken.address, tokenId]
  })

  const { sendTransaction, isSuccess, data, isLoading, status } = useSendTransaction(config)

  const unapprove = async () => {
    console.log({ sendTransaction, isSuccess, data, isLoading });
    if (sendTransaction) {
      await sendTransaction()
    }
  }

  return {
    unapprove,
    isUnapproving: isLoading,
    data,
    isUnapproved: isSuccess,
    status
  }
}

export default useUnapprove
