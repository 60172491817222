import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import "./SectionFifth.css";

const SectionFifth = ({total,fraction}) => {
  return (
    <div  style={{ border: "1px solid #0d6efd",marginLeft:'-10px' }} className=" rounded-2 mt-2 p-2 w-100">
      <div className="row">
        <div className="col-7 d-flex gap-2 flex-row  align-items-center total-text">
        <p className="mb-0">Ownership:10%</p>
        <p className="fw-bold mb-0">
          {fraction} <GiHamburgerMenu  />
        </p>
      </div>
      <div className="col-5 ">
        <h6 className="m-0 p-0 total-text d-none d-lg-block">Total: &#0165;{total}</h6>
        <p className="m-0 p-0 total-text d-lg-none">Total: &#0165;{total}</p>
      </div>
      </div>
    </div>
  );
};

export default SectionFifth;
