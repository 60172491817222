import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams,Link} from 'react-router-dom';

function VerifyEmail() {
  const { token } = useParams();
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const verifyEmails = async () => {
      try {
        // Send verification request to the backend
        const response = await axios.get(`/api/verify/${token}`);
        console.log(response)
        setIsVerified(true);
      } catch (error) {
        setError('Email verification failed');
      }
    };

    verifyEmails();
  }, [token]);



  return (
    <div>
      {isVerified ? (
        <div className="card" style={{margin:"150px 0",border:"1px solid #333"}}>
        <div className="card-body text-center ">
            Thank you, your email has been verified! Go to <Link to="/login">LOGIN</Link>
        </div>
        </div>
      ) : (
        <div className="card">
        <div className="card-body">
             {error} <br/>
            <Link to="/">HOME</Link>
        </div>
        </div>
      )}
    </div>
  );
}

export default VerifyEmail;