import { usePrepareContractWrite, useSendTransaction } from 'wagmi'
import { chainConfig } from '../../ChainConfig'
import collectivePurchaseAbi from './collectivePurchaseAbi.json'
import ERC721Abi from '../../../contracts/ERC721.json'
import { ethers } from 'ethers'

const useApprove = (contractAddress, tokenId, chainId) => {
  const { products, nativeToken } = chainConfig(chainId)
  console.log("chainId in useapprove", contractAddress);
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: ERC721Abi,
    functionName: 'approve',
    chainId,
    args: [products.specific.contract.collectivePurchase, tokenId]
  })
  console.log("chainId in useapprove", config);

  const { sendTransaction, isSuccess, data, isLoading, status } = useSendTransaction(config)

  const approve = async () => {
    console.log({ sendTransaction, isSuccess, data, isLoading });
    if (sendTransaction) {
      await sendTransaction()
    }
  }

  return {
    approve,
    isApproving: isLoading,
    data,
    isApproved: isSuccess,
    status
  }
}

export default useApprove
